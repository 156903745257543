import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import {
  IAddTableQuota,
  IEditTableQuota,
  IFilialQuota,
  IQuota,
  IQuotaQuery,
  IQuotaRelationDTO,
  IQuotaRelationForm,
  IQuotasData,
  IQuotasRelationsPayload,
  ISetQuotaByRolePayload,
  ITableQuotasData
} from './types';
import { customBaseQuery } from '@/utils/customBaseQuery';
import { IDefaultFilter, IFullResponse } from '@/types/api';

type IQuotaProvideType = 'Quotas' | 'FilialQuota' | 'QuotasRelations';
const tagTypes: IQuotaProvideType[] = ['Quotas', 'FilialQuota', 'QuotasRelations'];
export const quotasApi = createApi({
  reducerPath: 'api/quotas',
  baseQuery: customBaseQuery(`${BASE_URL}`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getQuotas: build.query<IQuotasData, IQuotaQuery>({
      query: (payload) => ({
        url: '/v2/userquotas/list',
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<ITableQuotasData>) => {
        const { Data } = response.Response;
        const defaultItem = Data.Items.find((quota) => quota?.IsDefault);
        return {
          TotalCount: Data.TotalCount,
          DefaultItemId: defaultItem?.Id,
          Quotas: Data.Items.map(
            (quota): IQuota => ({
              id: quota.Id,
              name: quota.Name,
              size: quota.Size,
              users: quota.UserCount
            })
          )
        };
      },
      providesTags: ['Quotas']
    }),
    getFilialQuota: build.query<IFilialQuota, string | number>({
      query: (id) => ({
        params: {
          id
        },
        url: 'v2/customer/quota',
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<IFilialQuota>) => ({
        ...response.Response.Data
      }),
      providesTags: ['FilialQuota']
    }),
    updateFilialQuota: build.mutation<IFilialQuota, Omit<IFilialQuota, 'UsedSize'>>({
      query: (payload) => ({
        body: payload,
        url: 'v2/customer/quota',
        method: 'POST'
      }),
      transformResponse: (response: IFullResponse<IFilialQuota>) => ({
        ...response.Response.Data
      }),
      invalidatesTags: ['Quotas', 'FilialQuota']
    }),
    createQuota: build.mutation<IQuota, IAddTableQuota>({
      query: (payload) => ({
        url: '/v2/userquotas',
        body: payload,
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IEditTableQuota>) => {
        const { Data } = response.Response;
        return {
          id: Data.Id,
          name: Data.Name,
          size: Data.Size,
          users: null
        };
      },
      invalidatesTags: ['Quotas']
    }),
    updateQuota: build.mutation<IQuota, IEditTableQuota>({
      query: (payload) => ({
        url: '/v2/userquotas',
        body: payload,
        method: 'put'
      }),
      transformResponse: (response: IFullResponse<IEditTableQuota>) => {
        const { Data } = response.Response;
        return {
          id: Data.Id,
          name: Data.Name,
          size: Data.Size,
          users: null
        };
      },
      invalidatesTags: ['Quotas']
    }),
    setQuotaByRole: build.mutation<IQuota, ISetQuotaByRolePayload>({
      query: (payload) => ({
        url: '/v2/userquotas/setbyrole',
        body: payload,
        method: 'post'
      }),
      invalidatesTags: ['Quotas']
    }),
    deleteQuota: build.mutation<IQuota, number>({
      query: (quotaId) => ({
        url: `/v2/userquotas?id=${quotaId}`,
        method: 'delete'
      }),
      invalidatesTags: ['Quotas']
    }),
    // Relation
    addQuotaRelations: build.mutation<IQuotaRelationForm, any>({
      query: (payload) => ({
        url: '/v1/quotarelations',
        body: payload,
        method: 'post'
      }),
      invalidatesTags: ['Quotas', 'QuotasRelations']
    }),
    editQuotaRelations: build.mutation<IQuotaRelationForm, any>({
      query: (payload) => ({
        url: '/v1/quotarelations',
        body: payload,
        method: 'put'
      }),
      invalidatesTags: ['Quotas', 'QuotasRelations']
    }),
    getQuotasRelations: build.query<
      { Items: IQuotaRelationDTO[]; TotalCount: number },
      IQuotasRelationsPayload
    >({
      query: (payload) => ({
        url: '/v1/quotarelations/list',
        method: 'post',
        body: payload
      }),
      providesTags: ['QuotasRelations']
    }),
    deleteQuotaRelation: build.mutation<void, { entityType: number; entityId: number }>({
      query: ({ entityType, entityId }) => ({
        url: `/v1/quotarelations?entitytype=${entityType}&entityid=${entityId}`,
        method: 'delete'
      }),
      invalidatesTags: ['Quotas', 'QuotasRelations']
    }),
    getDefaultQuotaRelationFilters: build.query<
      IDefaultFilter[],
      { count: number; customerId: number }
    >({
      query: ({ count, customerId }) => ({
        url: `/v1/quotarelations/filters/default?count=${count}&customerId=${customerId}`,
        method: 'get'
      }),
      transformResponse: (response: { DefaultFilters: IDefaultFilter[] }) => {
        return response.DefaultFilters.map((item) => ({
          ...item,
          Items: item.Items?.map((filterItem) => ({
            ...filterItem,
            Count: undefined,
            checked: false
          })),
          hideSearch: false
        })).sort((a, b) => a.Type - b.Type);
      },
      providesTags: ['QuotasRelations']
    })
  })
});

export const {
  useGetQuotasQuery,
  useLazyGetQuotasQuery,
  useLazyGetFilialQuotaQuery,
  useGetFilialQuotaQuery,
  useUpdateFilialQuotaMutation,
  useCreateQuotaMutation,
  useUpdateQuotaMutation,
  useDeleteQuotaMutation,
  useSetQuotaByRoleMutation,
  // Relation
  useAddQuotaRelationsMutation,
  useEditQuotaRelationsMutation,
  useGetQuotasRelationsQuery,
  useDeleteQuotaRelationMutation,
  useGetDefaultQuotaRelationFiltersQuery
} = quotasApi;
