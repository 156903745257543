import {
  DriveIcon,
  ChecklistIcon,
  FilialsIcon,
  MailIcon,
  SettingsIcon,
  ShieldIcon,
  UsersIcon,
  TimezonesIcon
} from '@assets/icons';
import { ROUTES } from '@/containers/routes/constants';
import { INavbarMenu } from '@/mock-data/navbar-menu';
import { useTranslation } from 'react-i18next';

export const useGetNavMenuItems = (isSaaSProduct: boolean) => {
  const { t } = useTranslation();
  const editProfileNavItems: INavbarMenu[] = [
    {
      label: t('profile.personalData'),
      icon: UsersIcon,
      value: ROUTES.profile.pages.personalData
    },
    { label: t('profile.company'), icon: FilialsIcon, value: ROUTES.profile.pages.filials },
    { label: t('profile.safety'), icon: ShieldIcon, value: ROUTES.profile.pages.safety },
    {
      label: t('profile.emailAccount'),
      icon: MailIcon,
      value: ROUTES.profile.pages.mailAccount
    },
    { label: t('filials.diskSpace.disk'), icon: DriveIcon, value: ROUTES.profile.pages.disk },
    { label: t('profile.settings'), icon: SettingsIcon, value: ROUTES.profile.pages.settings },
    { label: t('profile.timezones'), icon: TimezonesIcon, value: ROUTES.profile.pages.timezones }
  ];
  if (isSaaSProduct) {
    editProfileNavItems.push({
      label: t('license.licenses'),
      icon: ChecklistIcon,
      value: ROUTES.profile.pages.licenses
    });
  }
  return { editProfileNavItems };
};
