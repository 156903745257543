import { Box } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { themeColors } from '@/theme/colors';
import { useTranslation } from 'react-i18next';

export const useConfirmCancelForm = (modalClose: () => void) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    modalClose();
  };

  const openCancelCreateUser = () => {
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: t('warning'),
        modalBody: <Box mb={24}>{t('profile.cancelWarning')}</Box>,
        confirmProps: {
          'data-cy': 'continue',
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          'data-cy': 'cancel',
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%',
            '& .mantine-Button-label': {
              color: 'black'
            }
          }
        },
        labels: { confirm: t('continue'), cancel: t('cancel') },
        onConfirm: () => handleConfirm()
      }
    });
  };

  return openCancelCreateUser;
};
