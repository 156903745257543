import { GroupItem } from '@/components/modal/select-group/group-item';
import { styleScroll } from '@/components/scroll-container';
import { ISelectedUser, ISortedListByEmailSettings } from '@/entities/admin-app/users';
import { ActionIcon, Box, Collapse, Flex, Text, ScrollArea, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAlertTriangle, IconChevronDown, IconChevronRight } from '@tabler/icons';
import { FC, useState } from 'react';
import { useStyles } from '../styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/containers/routes/constants';

interface IFilialList {
  sortedList: ISortedListByEmailSettings[];
  handleClickUser: (selectedUser: ISelectedUser) => void;
  isLoading: boolean;
}

export const UsersListByVerifiedFilials: FC<IFilialList> = ({
  sortedList,
  handleClickUser,
  isLoading
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { theme } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const [isOpenStates, setIsOpenStates] = useState<boolean[]>([]);

  const toggleFilial = (index: number) => {
    const newIsOpenStates = [...isOpenStates];
    newIsOpenStates[index] = !newIsOpenStates[index];
    setIsOpenStates(newIsOpenStates);
  };

  return (
    <Box>
      <Flex onClick={toggle} align="center">
        <Text td="underline" sx={{ cursor: 'pointer' }}>
          {t('sendLetter.showUsers')}
        </Text>
        <ActionIcon ml="5px">
          {opened ? (
            <IconChevronDown size={18} color="#667085" />
          ) : (
            <IconChevronRight size={18} color="#667085" />
          )}
        </ActionIcon>
      </Flex>
      {opened ? (
        <ScrollArea.Autosize
          sx={{
            maxHeight: '300px'
          }}
          styles={styleScroll}
        >
          {sortedList.length && !isLoading
            ? sortedList.map((filial, index) => {
                return (
                  <Collapse in={opened} key={index} py="5px">
                    <Flex align="center" onClick={() => toggleFilial(index)}>
                      <ActionIcon>
                        {isOpenStates[index] ? (
                          <IconChevronDown size={18} color="#667085" />
                        ) : (
                          <IconChevronRight size={18} color="#667085" />
                        )}
                      </ActionIcon>
                      <Text fw="bold" sx={{ cursor: 'pointer' }} ml="5px">
                        {filial.CustomerName}
                      </Text>{' '}
                      {!filial.HasSettings ? (
                        <Menu trigger="hover" openDelay={100} closeDelay={400} zIndex="1400">
                          <Menu.Target>
                            <ActionIcon variant="transparent" ml="10px">
                              <IconAlertTriangle
                                size="20"
                                color="red"
                                stroke={1.5}
                                cursor="pointer"
                              />
                            </ActionIcon>
                          </Menu.Target>
                          <Menu.Dropdown
                            c="theme.colors.dark"
                            sx={{
                              background: theme.colors.brandGray[1],
                              borderRadius: theme.spacing.xs,
                              padding: '8px'
                            }}
                          >
                            <Text fz="sm">
                              {t('sendLetter.noSettings')}{' '}
                              <Link
                                className={classes.link}
                                target="_blank"
                                to={`${ROUTES.filials.edit.clearPath}/${filial.CustomerId}/mailing-setup`}
                              >
                                {t('sendLetter.params')}
                              </Link>
                            </Text>
                          </Menu.Dropdown>
                        </Menu>
                      ) : null}
                    </Flex>
                    {isOpenStates[index] && (
                      <ScrollArea
                        sx={{ height: filial.Users.length > 4 ? '200px' : 'auto', width: '98%' }}
                      >
                        {filial.Users.map((user, innerIndex) => (
                          <GroupItem
                            key={innerIndex}
                            checked={user.isChecked}
                            disabled={user.isDisabled}
                            label={`${user.surName} ${user.firstName ?? '-'}`}
                            handleChange={() => handleClickUser(user)}
                            value={Number(user.id)}
                            withCheckbox
                          />
                        ))}
                      </ScrollArea>
                    )}
                  </Collapse>
                );
              })
            : null}
        </ScrollArea.Autosize>
      ) : null}
    </Box>
  );
};
