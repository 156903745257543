import { Stack } from '@mantine/core';
import { Modal } from '@/components/modal';
import { CardContainer } from '../../cardContainer';
import { IconUsersGroup } from '@tabler/icons-react';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { useGetRolesForUserQuery } from '@/entities/admin-app/directories/api';
import { RolesCreateForm } from '@/containers/pages/roles/components/create-item/form';
import { useState } from 'react';

export const RolesSection = () => {
  const { t } = useTranslation();

  const { data: rolesData } = useGetRolesForUserQuery({
    Count: 10,
    Offset: 0,
    Query: '',
    FilterConditions: []
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateRole = () => {
    setIsModalOpen(true);
  };

  const rolesMenu: IListItemProps = {
    withCheck: false,
    items: [
      {
        name: t('roles.list'),
        link: ROUTES.roles.path,
        badge: rolesData && rolesData.TotalCount
      },
      { name: t('profile.addRole'), openModal: handleCreateRole }
    ]
  };

  return (
    <>
      <CardContainer sectionName={t('profile.roles')} Icon={IconUsersGroup}>
        <Stack gap="11px" sx={{ width: '100%' }}>
          <ListItem items={rolesMenu.items} withCheck={rolesMenu.withCheck} />
        </Stack>
      </CardContainer>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('roles.create')}
        zIndex={1002}
      >
        <RolesCreateForm close={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};
