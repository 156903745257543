import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  select: {
    '& .mantine-Select-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  },

  selectInput: {
    cursor: 'pointer',
    '& .mantine-Input-input': {
      borderRadius: '8px'
    }
  },

  options: {
    '& .mantine-Combobox-option': {
      '&[data-combobox-active=true]': {
        backgroundColor: theme.colors.blue[5],
        color: theme.white
      }
    }
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  }
}));
