import React from 'react';
import {
  Button,
  // Flex,
  LoadingOverlay,
  // Modal,
  PasswordInput,
  SimpleGrid,
  Text,
  Box,
  UnstyledButton
} from '@mantine/core';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { Modal } from '@/components/modal';
import { useLazyChangePasswordLinkedUserEmailQuery } from '@/entities/admin-app/email/api';
import { showNotification } from '@mantine/notifications';
import { useStyles } from '@/containers/pages/user/components/sections/linked-emails/styles';
import { z } from 'zod';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { useTranslation } from 'react-i18next';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { defaultMailServerPasswordOptions } from '@/mock-data/passwordOptions';
import { useGeneratePolicy } from '@hooks/password/useGeneratePolicy';
import { generatePasswordRegExp } from '@/utils/passwordUtils';
import { ErrorsEnum } from '@/types/enums';

interface IChangePasswordModalProps {
  opened: boolean;
  handleClose: () => void;
  selectedEmail: ILinkedEmail | null;
}
export const ChangePasswordModal = ({
  selectedEmail,
  opened,
  handleClose
}: IChangePasswordModalProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const generatePasswordPolicyString = useGeneratePolicy();
  const passwordRegexp = generatePasswordRegExp({ options: defaultMailServerPasswordOptions });
  const [changeEmailPassword, { isFetching, isError }] =
    useLazyChangePasswordLinkedUserEmailQuery();

  const modalClose = () => {
    passwordsForm.reset();
    handleClose();
  };

  const passwordsFormSchema = z.object({
    Password: z.string().min(1, { message: t('profile.addOldPassword') }),
    NewPassword: z.string().regex(passwordRegexp, {
      message: generatePasswordPolicyString(defaultMailServerPasswordOptions)
    })
  });

  const passwordsForm = useForm({
    initialValues: {
      Password: '',
      NewPassword: ''
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(passwordsFormSchema, values)
  });

  const handleChangePasswords = async () => {
    const { Password, NewPassword } = passwordsForm.values;
    try {
      const res = await changeEmailPassword({
        EmailId: selectedEmail?.Id || 0,
        Password,
        NewPassword
      });

      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        const nativeErrorCode = (res as any)?.error?.data.ErrorCode;
        showNotification({
          title:
            nativeErrorCode === 406 || codeError === ErrorsEnum.InvalidLoginOrPassword
              ? t('incorrectOldEmailPassword')
              : '',
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('profile.updatePasswordSuccessMessage'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });

        modalClose();
      }
    } catch (err: any) {
      console.warn('Ошибка смены пароля', err);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={modalClose}
      title={t('profile.passwordChangeTitle')}
      zIndex={1300}
    >
      <Box className={classes.modalRoot}>
        <Box className={classes.modalContainer}>
          <Text>{t('profile.passwordChangeWarning')}</Text>
          <SimpleGrid cols={1} mt={16} w="100%">
            <PasswordInput
              placeholder={t('profile.password')}
              label={t('profile.oldPassword')}
              withAsterisk
              {...passwordsForm.getInputProps('Password')}
            />
            <PasswordInput
              placeholder={t('profile.newPassword')}
              label={t('profile.newPassword')}
              withAsterisk
              {...passwordsForm.getInputProps('NewPassword')}
            />
          </SimpleGrid>
        </Box>
        <Box className={classes.modalFooter}>
          <Button
            disabled={!passwordsForm.isValid() || !selectedEmail}
            onClick={() => handleChangePasswords()}
            w="49%"
          >
            {t('profile.passwordChange')}
          </Button>
          <UnstyledButton className={classes.modalCancelBtn} w="49%" onClick={() => modalClose()}>
            {t('profile.cancel')}
          </UnstyledButton>
        </Box>
        <LoadingOverlay visible={isFetching && !isError} overlayProps={{ blur: 2 }} />
      </Box>
    </Modal>
  );
};
