import { FC, ReactNode } from 'react';
import { ActionIcon, TextInput } from '@mantine/core';
import { useStyles } from './styles';
import { UseFormReturnType } from '@mantine/form';
import { IconX } from '@tabler/icons';

interface ITextFieldProps {
  fieldName: string;
  form: UseFormReturnType<any>;
  className?: string;
  label?: string | ReactNode;
  disabled?: boolean;
  clearable?: boolean;
  rightSection?: ReactNode;
  isRequired?: boolean;
  withAsterisk?: boolean;
  dataAttribute?: string;
  placeholder?: string;
  description?: string;
}

export const TextFieldInput: FC<ITextFieldProps> = ({
  form,
  fieldName,
  className,
  disabled,
  label,
  clearable,
  rightSection,
  isRequired,
  withAsterisk,
  dataAttribute,
  placeholder,
  description
}) => {
  const { classes, cx } = useStyles();

  const clearValue = () => {
    form.setFieldValue(fieldName, '');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!newValue.startsWith(' ')) {
      form.setFieldValue(fieldName, newValue);
    }
  };

  const rightSectionRender = () => {
    if (rightSection) {
      return rightSection;
    } else if (form.getInputProps(fieldName).value && clearable) {
      return (
        <ActionIcon variant="transparent" onClick={clearValue}>
          <IconX color="#667085" />
        </ActionIcon>
      );
    }
  };

  return (
    <TextInput
      className={cx(classes.textFieldInput, className)}
      label={label}
      withAsterisk={withAsterisk}
      required={isRequired}
      disabled={disabled}
      rightSection={rightSectionRender()}
      error={form.getInputProps(fieldName).error}
      value={form.getInputProps(fieldName).value || ''}
      onChange={handleInputChange}
      data-name={fieldName}
      data-cy={dataAttribute}
      placeholder={placeholder}
      description={description}
    />
  );
};
