import { Flex, Text } from '@mantine/core';
import { FC } from 'react';
import { useStyles } from './styles';
import { IModuleItem } from '../sections/modules';
import { useNavigate } from 'react-router-dom';

interface IModuleItemProps {
  item: IModuleItem;
}

export const ModuleItem: FC<IModuleItemProps> = ({ item }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleRedirect = (item: IModuleItem) => {
    navigate(item.link);
  };

  return (
    <Flex
      align="center"
      gap="10px"
      className={classes.moduleItem}
      onClick={() => handleRedirect(item)}
    >
      <img src={item.icon} alt="icon" width="24px" />
      <Text fz="14px" fw="400" c="#848E98">
        {item.label}
      </Text>
    </Flex>
  );
};
