import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  errorContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '180px',
    flexDirection: 'column',
    gap: '10px'
  },

  errorText: {
    fontSize: '14px',
    color: '#848E98',
    textAlign: 'center'
  }
}));
