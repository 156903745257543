import React, { createContext, Dispatch, FC, SetStateAction, useEffect } from 'react';
import { HomePanel } from './home';
import { MoreInfoPanel } from './more-info';

import { PanelsEnum } from '@/types/enums/roles';
import { useGetRolesInfoCommonQuery } from '@/entities/admin-app/roles/api';
import { IRowData } from '@components/simple-table/types';
import { IRolesInfoCommon } from '@/entities/admin-app/roles';
import { useAppSelector } from '@/hooks/redux/redux';
import { Flex, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export interface IRolesInfoMainContext {
  selectedRolesId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  rolesInfoCommon?: IRolesInfoCommon;
}

export const RolesInfoMainContext = createContext<IRolesInfoMainContext>(
  {} as IRolesInfoMainContext
);

export interface IPanelsProps {
  selectedRolesId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  handleClose: () => void;
}

export const Panels: FC<IPanelsProps> = ({ selectedRolesId, setRowData, handleClose }) => {
  const { data, isLoading, isError } = useGetRolesInfoCommonQuery(selectedRolesId);
  const { t } = useTranslation();
  const { selectedPanel } = useAppSelector((state) => state.roles);

  const initialValues: IRolesInfoMainContext = {
    selectedRolesId,
    setRowData,
    rolesInfoCommon: data
  };

  useEffect(() => {
    if (isError) {
      notifications.show({
        title: t('requestsErrors.error'),
        message: t('roles.roleNotFound'),
        color: 'red'
      });
      handleClose();
    }
  }, [isError]);

  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" h="100vh">
          <Loader />
        </Flex>
      ) : (
        <RolesInfoMainContext.Provider value={initialValues}>
          {selectedPanel === PanelsEnum.generalInformation && <HomePanel />}
          {selectedPanel === PanelsEnum.editInformation && <MoreInfoPanel />}
        </RolesInfoMainContext.Provider>
      )}
    </>
  );
};
