import { useMemo } from 'react';
import { useGetDefaultUserFiltersQuery } from '@/entities/admin-app/users/api';
import { IFilterMenu } from '@/types/api';
import { useTranslation } from 'react-i18next';
import { IFiltersEnumType } from '@/types/enums';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { DefaultFilter } from '@/entities/admin-app/users';

interface FiltersSettingsInterface {
  [index: number]: {
    hideSearch: boolean;
  };
}

export const filtersSettings: FiltersSettingsInterface = {
  1: {
    hideSearch: false
  },
  2: {
    hideSearch: true
  },
  3: {
    hideSearch: false
  },
  4: {
    hideSearch: false
  },
  5: {
    hideSearch: false
  },
  6: {
    hideSearch: true
  },
  7: {
    hideSearch: true
  },
  8: {
    hideSearch: true
  },
  9: {
    hideSearch: true
  },
  10: {
    hideSearch: false
  },
  11: {
    hideSearch: false
  },
  12: {
    hideSearch: false
  },
  13: {
    hideSearch: false
  },
  14: {
    hideSearch: false
  },
  15: {
    hideSearch: false
  },
  16: {
    hideSearch: false
  },
  17: {
    hideSearch: false
  }
};

const transformFilters = (data: DefaultFilter[], t: (key: string) => string): IFilterMenu[] => {
  return data
    .map((item) => ({
      ...item,
      label:
        item.Type in IFiltersEnumType
          ? t(`filters.${filtersLabelsKeys[item.Type]}`)
          : t('filters.title'),
      Items: item.Items?.map((filterItem) => ({ ...filterItem, checked: false })),
      hideSearch: filtersSettings[item.Type]?.hideSearch ?? false
    }))
    .sort((a, b) => a.Type - b.Type)
    .filter((item) => item.Type !== 10);
};

export const useGetUsersFilters = () => {
  const { t } = useTranslation();
  const {
    data: defaultFiltersData,
    isSuccess: defaultFiltersSuccess,
    isFetching
  } = useGetDefaultUserFiltersQuery(20);

  const filtersMenuList = useMemo(() => {
    return defaultFiltersSuccess ? transformFilters(defaultFiltersData || [], t) : [];
  }, [defaultFiltersData, defaultFiltersSuccess, t]);

  return { filtersMenuList, isFetching };
};
