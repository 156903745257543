import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { Badge, Box, Button, Flex, PasswordInput, Text, UnstyledButton } from '@mantine/core';
import { UserAvatar } from '@/components/user-avatar';
import { IconCopy } from '@tabler/icons';
import { universalNotification } from '@/lib/utils/notification';
import { copyToClipboard } from '@/containers/pages/user/utils';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { IUserInfo } from '@/entities/admin-app/user';
import { ActionIcon } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import OverflowTip from '@/components/OverflowTip';

interface ISuccessUserModal {
  modalClose: () => void;
  createUser: () => void;
  userInfo?: IUserInfo;
  avatar: string;
}

export const SuccessUserModal = ({
  modalClose,
  createUser,
  userInfo,
  avatar
}: ISuccessUserModal) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const copyText = async (textToCopy?: string | null) => {
    if (textToCopy) {
      try {
        await copyToClipboard(textToCopy);
        universalNotification({
          isError: false,
          error: null,
          isSuccess: true,
          successTitle: t('copied')
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Box className={classes.modalContainer} sx={{ paddingBottom: '100px' }}>
        <Box className={classes.root}>
          <UserAvatar size="126" src={avatar} user={userInfo?.Common} userId={userInfo?.Id} />
          <Text fz="26" py="15px" sx={{ textAlign: 'center' }}>
            {userInfo?.Common.SurName} {userInfo?.Common.FirstName} {userInfo?.Common.MiddleName}
          </Text>
          <Box className={classes.roleBox}>
            {userInfo?.Roles?.map((item) => {
              return (
                <Badge
                  className={classes.badge}
                  key={item.Id}
                  variant="outline"
                  data-cy={`${item.Name}`}
                >
                  {item.Name}
                </Badge>
              );
            })}
          </Box>

          <Flex className={classes.info}>
            <Flex className={classes.dataContainer}>
              {userInfo?.Credentials.Email && (
                <>
                  <Text className={classes.label} fz="10">
                    {t('profile.email')}:
                  </Text>
                  <Flex align="center" className={classes.description}>
                    <OverflowTip index={1300} className={classes.tip}>
                      {userInfo?.Credentials.Email}
                    </OverflowTip>
                    <ActionIcon onClick={() => copyText(userInfo?.Credentials.Email)} ml="xs">
                      <IconCopy stroke={1.5} size={17} />
                    </ActionIcon>
                  </Flex>
                </>
              )}
            </Flex>
            {isSaaS(Settings) ? null : (
              <Flex className={classes.dataContainer}>
                {userInfo?.Credentials.Login && (
                  <>
                    <Text className={classes.label} fz="10">
                      {t('profile.login')}:
                    </Text>

                    <Flex align="center" className={classes.description}>
                      <OverflowTip index={1300} className={classes.tip}>
                        {userInfo?.Credentials.Login}
                      </OverflowTip>
                      <ActionIcon onClick={() => copyText(userInfo?.Credentials.Login)} ml="xs">
                        <IconCopy stroke={1.5} size={17} />
                      </ActionIcon>
                    </Flex>
                  </>
                )}
              </Flex>
            )}
            {userInfo?.Credentials.Password && (
              <Flex className={classes.dataContainer}>
                <Text className={classes.label} fz="10">
                  {t('profile.password')}:
                </Text>
                <Flex align="center">
                  <PasswordInput
                    className={classes.passwordInput}
                    style={{
                      width: `calc(${userInfo?.Credentials.Password.length + 1}ch + ${
                        isMobile ? 50 : 30
                      }px)`
                    }}
                    value={userInfo?.Credentials.Password}
                    readOnly
                  />
                  <ActionIcon ml="-5px" onClick={() => copyText(userInfo?.Credentials.Password)}>
                    <IconCopy stroke={1.5} size={17} color="#868e96" className={classes.copyIcon} />
                  </ActionIcon>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>
      <Box
        className={classes.modalFooter}
        sx={{
          position: 'fixed',
          bottom: '0',
          zIndex: 1,
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <Button
          size="sm"
          radius={8}
          w="49%"
          onClick={() => {
            modalClose();
          }}
        >
          {t('requestResetPassword.ready')}
        </Button>

        <UnstyledButton
          ml={8}
          className={classes.cancelBtn}
          w="49%"
          onClick={() => {
            createUser();
          }}
        >
          {t('profile.createMore')}
        </UnstyledButton>
      </Box>
    </>
  );
};
