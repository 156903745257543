import { Flex, FlexProps, Skeleton } from '@mantine/core';

export const FilterSkeleton = ({ count = 3, ...props }: { count?: number } & FlexProps) => {
  return (
    <Flex justify="space-between" align="center" gap="sm" {...props}>
      {[...new Array(count)].map((_, index) => (
        <Skeleton key={index} height={32} width={index ? 89 : 144} radius="xl" />
      ))}
    </Flex>
  );
};
