import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  EntityTypeDictionary,
  relationQuotaEntityType
} from '@/containers/pages/filials/edit/utiils';
import { Action } from '../table/Action';
import { formatDataSizeUnits } from '@/lib/utils/format';
import { useStyles } from '../styles';

export const useGetColumns = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const columnsData: IColumnData[] = [
    {
      width: 'auto',
      dataIndex: 'Quota.Name',
      title: t('filials.diskSpace.quotasSetup.table.quota'),
      render: (item: IRowData) => item.Quota?.Name
    },
    {
      width: 'auto',
      dataIndex: 'Size',
      title: t('filials.diskSpace.quotasSetup.table.size'),
      render: (item: IRowData) => formatDataSizeUnits(item.QuotaSize)
    },
    {
      width: 'auto',
      dataIndex: 'ObjectType',
      title: t('filials.diskSpace.quotasSetup.table.objectType'),
      render: (item: IRowData) =>
        EntityTypeDictionary[item.Entity.Type as relationQuotaEntityType]?.label
    },
    {
      width: 'auto',
      dataIndex: 'Object',
      title: t('filials.diskSpace.quotasSetup.table.object'),
      render: (item: IRowData) => (
        <Link to={item.Url} target="_blank" className={classes.link}>
          {item.Entity?.Name}
        </Link>
      )
    },
    {
      width: 'auto',
      dataIndex: 'Owner',
      title: t('filials.diskSpace.quotasSetup.table.owner'),
      render: (item: IRowData) => (
        <Link to={item.Url} target="_blank" className={classes.link}>
          {item.Owner?.Name}
        </Link>
      )
    },
    {
      width: '80px',
      dataIndex: 'Action',
      title: '',
      hideColumn: false,
      render: (item: IRowData) => <Action item={item} />
    }
  ];

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
