import { FC } from 'react';
import { IconTrash } from '@tabler/icons';
import { IRowData } from '@/components/simple-table/types';
import { ActionIcon, Group } from '@mantine/core';
import { useAppSelector } from '@/hooks/redux/redux';
import { canEditRole } from '@/lib/utils/access';
import { EditProfileIcon } from '@/assets/icons';
import { useActions } from '@/hooks/redux/action';
import { IQuotaRelationDTO } from '@/entities/admin-app/quotas';
import { useDeleteQuotas } from '@/hooks/quotas/useDeleteQuotas';

interface IActionProps {
  item?: IRowData;
}

export const Action: FC<IActionProps> = ({ item }) => {
  const { selectedRolesId } = useAppSelector((state) => state.roles);
  const { openDeleteQuotas } = useDeleteQuotas();
  const { setSelectedQuotaRelation } = useActions();

  const handleClickModal = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    openDeleteQuotas({
      entityId: item?.Entity.Id,
      entityType: item?.Entity.Type
    });
  };

  const editRole = () => {
    setSelectedQuotaRelation(item as IQuotaRelationDTO);
  };

  return (
    <Group gap={4} justify="flex-end" wrap="nowrap">
      {!selectedRolesId &&
        (canEditRole(item?.Type) ? (
          <ActionIcon
            data-cy="editRole"
            className="action-icon"
            data-action="action"
            onClick={editRole}
          >
            <EditProfileIcon size={24} />
          </ActionIcon>
        ) : null)}

      {canEditRole(item?.Type) ? (
        <ActionIcon
          data-cy="roleActions"
          className="action-icon"
          onClick={handleClickModal}
          data-action="action"
        >
          <IconTrash size="1.5rem" color="#667085" stroke={1.5} style={{ pointerEvents: 'none' }} />
        </ActionIcon>
      ) : null}
    </Group>
  );
};
