import { LinkEmailModal } from '@/containers/pages/user/components/sections/linked-emails/modals/linkEmailModal';
import { ChangePasswordModal } from '@/containers/pages/user/components/sections/linked-emails/modals/changePasswordModal';
import { DeleteEmailModal } from '@/containers/pages/user/components/sections/linked-emails/modals/deleteEmailModal';
import { CreateEmailAccount } from '@/containers/pages/user/components/sections/linked-emails/modals/createEmailAccount';
import { ILinkedEmail } from '@/entities/admin-app/email';
import { UseFormReturnType } from '@mantine/form';

interface Props {
  isOpenedCreateModal: boolean;
  editUser: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  opened: boolean;
  isOpenedDeleteEmailModal: boolean;
  isOpenedChangePasswordModal: boolean;
  currentId?: number;
  filialId?: number | null;
  sectionFormKey: string;
  selectedEmail: ILinkedEmail | null;
  form: UseFormReturnType<any>;
  listEmails: ILinkedEmail[] | null;

  closeCreateModal(): void;
  closePasswordModal(): void;
  close(): void;
  closeDeleteModal(): void;
  handleAddEmail(email: ILinkedEmail): Promise<void>;
}

export const LinkEmailsModals = (props: Props) => {
  const {
    isOpenedCreateModal,
    filialId,
    editUser,
    handleAddEmail,
    isLoading,
    isSuccess,
    selectedEmail,
    opened,
    isOpenedChangePasswordModal,
    closePasswordModal,
    close,
    isOpenedDeleteEmailModal,
    closeDeleteModal,
    currentId,
    form,
    sectionFormKey,
    closeCreateModal,
    listEmails
  } = props;

  const visibleCreateEmailModal = isOpenedCreateModal && filialId;

  return (
    <>
      <LinkEmailModal
        opened={opened}
        handleClose={close}
        handleAddEmail={handleAddEmail}
        filialId={filialId}
        isLoading={isLoading}
        isSuccess={isSuccess}
        editUser={editUser}
      />
      <ChangePasswordModal
        selectedEmail={selectedEmail}
        opened={isOpenedChangePasswordModal}
        handleClose={closePasswordModal}
      />
      <DeleteEmailModal
        selectedEmail={selectedEmail}
        opened={isOpenedDeleteEmailModal}
        handleClose={closeDeleteModal}
        editUser
        currentId={currentId}
        form={form}
        sectionFormKey={sectionFormKey}
        listEmails={listEmails}
      />
      {visibleCreateEmailModal && (
        <CreateEmailAccount
          handleClose={closeCreateModal}
          opened={isOpenedCreateModal}
          handleAddEmail={handleAddEmail}
          filialId={filialId}
        />
      )}
    </>
  );
};
