import {
  GearIcon,
  ShieldIcon,
  MailIcon,
  SendIcon,
  UsersIcon,
  inviteUserIcon,
  DriveIcon
} from '@assets/icons';
import { ROUTES } from '@/containers/routes/constants';
// import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { INavbarMenu } from '@/mock-data/navbar-menu';
import i18n from '@/entities/i18next';

i18n.t('statistics.activity');

export const menuCommonPages: INavbarMenu[] = [
  {
    label: i18n.t('filials.settings.general'),
    icon: GearIcon,
    value: ROUTES.filials.edit.pages.common.settings
  },
  {
    label: i18n.t('filials.settings.auth'),
    icon: UsersIcon,
    value: ROUTES.filials.edit.pages.auth.ldap
  },
  {
    label: i18n.t('profile.safety'),
    icon: ShieldIcon,
    value: ROUTES.filials.edit.pages.common.security
  },
  // { label: 'Настройка SMTP', icon: MailIcon, value: ROUTES.filials.edit.pages.common.smtp },
  {
    label: i18n.t('servers.title'),
    icon: MailIcon,
    value: ROUTES.filials.edit.pages.common.mailServers
  },
  {
    label: i18n.t('filials.settings.linkSettings'),
    icon: SendIcon,
    value: ROUTES.filials.edit.pages.common.mailingSetup
  },
  {
    label: i18n.t('register.title'),
    icon: inviteUserIcon,
    value: ROUTES.filials.edit.pages.auth.invite
  },
  {
    label: i18n.t('profile.retentionPolicy'),
    icon: DriveIcon,
    value: ROUTES.filials.edit.pages.common.diskSpace
  }
  // {
  //   label: 'Импорт пользователей',
  //   icon: LinkIcon,
  //   value: ROUTES.filials.edit.pages.common.importUsers
  // }
];

// const list = [
//   {
//     label: i18n.t('filialSecurity.ipSecurityForUsers'),
//     value: FilialInfoPanelsEnum.ipSecurityUsers
//   },
//   {
//     label: i18n.t('filialSecurity.ipSecurityAdministration'),
//     value: FilialInfoPanelsEnum.ipSecurityAdm
//   },
//   { label: i18n.t('filialSecurity.sessionTitle'), value: FilialInfoPanelsEnum.sessionDuration },
//   { label: i18n.t('filialSecurity.passwordTitle'), value: FilialInfoPanelsEnum.passwordLimit }
//   // {
//   //   label: 'Настройки надежности пароля',
//   //   value: FilialInfoPanelsEnum.passwordSecurity
//   // }
// ];
// if (isFeature()) {
//   list.push({
//     label: 'Настройки надежности пароля',
//     value: FilialInfoPanelsEnum.passwordSecurity
//   });
// }

export enum emailServerConnectionType {
  None = 0,
  Auto = 1,
  SslOnConnect = 2,
  StartTls = 3,
  StartTlsWhenAvailable = 4
}

export const ConnectionTypeOptions = [
  {
    label: 'None',
    value: emailServerConnectionType.None.toString()
  },
  {
    label: 'Auto',
    value: emailServerConnectionType.Auto.toString()
  },
  {
    label: 'SslOnConnect',
    value: emailServerConnectionType.SslOnConnect.toString()
  },
  {
    label: 'StartTls',
    value: emailServerConnectionType.StartTls.toString()
  },
  {
    label: 'StartTlsWhenAvailable',
    value: emailServerConnectionType.StartTlsWhenAvailable.toString()
  }
];

export enum relationQuotaEntityType {
  Directory = 3,
  Role = 34,
  Room = 47,
  User = 5
}

export const EntityTypeDictionary: Record<relationQuotaEntityType, { label: string }> = {
  3: {
    label: i18n.t('entity.type.directory')
  },
  34: {
    label: i18n.t('entity.type.role')
  },
  47: {
    label: i18n.t('entity.type.room')
  },
  5: {
    label: i18n.t('entity.type.user')
  }
};

export function getEntityTypeFromUrl(url: string): relationQuotaEntityType | null {
  if (/(common|docs|shared-to-me|shared-access|favorites)/.test(url)) {
    return relationQuotaEntityType.Directory;
  } else if (/roles/.test(url)) {
    return relationQuotaEntityType.Role;
  } else if (/rooms/.test(url)) {
    return relationQuotaEntityType.Room;
  } else if (/profile/.test(url)) {
    return relationQuotaEntityType.User;
  }

  return null;
}
