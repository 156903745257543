import { useStyles } from './styles';
import { Box, ScrollArea } from '@mantine/core';
import { Modal } from '@components/modal';
import { useTranslation } from 'react-i18next';
import { initForm } from '@/containers/pages/user/consts/initValues';
import { FormErrors, UseFormReturnType, useForm } from '@mantine/form';
import { IUserInfo } from '@/entities/admin-app/user';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { getValidateErrors } from '@/lib/utils/validation';
import { z } from 'zod';
import iconUser from '@assets/icon-cant-create-user.svg';
import {
  validateSchemeCreateCredentials,
  validateSchemeCustomer,
  validateSchemeDataStorageQuota,
  validateSchemeEditUserInfo,
  validateSchemePositions,
  validateSchemeStatus
} from '../../schemes/validate';
import { useEffect, useState } from 'react';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { useAddMutation } from '@/entities/admin-app/user/api';
import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { generatePasswordRegExp } from '@/utils/passwordUtils';
import { useLazyGetFilialsInfoCommonQuery } from '@/entities/admin-app/filials/api';
import { useGeneratePolicy } from '@/hooks/password/useGeneratePolicy';
import { useLazyCheckCanCreateUserQuery } from '@/entities/admin-app/auth/api';
import { LoadingOverlay } from '@mantine/core';
import { MessageContainer } from '@/components/message-container';
import { ModuleUrl } from '@/entities/admin-app/auth';
import { SuccessUserModal } from './modal/success-user';
import { universalNotification } from '@/lib/utils/notification';
import { clearApiCacheByTags } from '@/entities/helpers';
import { usersApi } from '@/entities/admin-app/users/api';
import { Sections } from './components/sections';
import { defaultMailServerPasswordOptions } from '@/mock-data/passwordOptions';

export interface ICreateUserContext {
  form: UseFormReturnType<IUserInfo>;
  handleSubmit: (values: IUserInfo) => Promise<void>;
}

interface ICreateUserModal {
  opened: boolean;
  modalClose: () => void;
}

export const CreateUserModal = ({ opened, modalClose }: ICreateUserModal) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [isCreateModal, setIsCreateModal] = useState(true);
  const { Settings } = useAppSelector((state) => state.userInfo);
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [preview, setPreview] = useState<string>('');
  const [checkCanCreate, { data: canCreateUserData, isSuccess: SuccessCheckCreateUser }] =
    useLazyCheckCanCreateUserQuery();

  const generatePasswordPolicyString = useGeneratePolicy();

  const [isPasswordEnabled, setIsPasswordEnabled] = useState<boolean>(false);
  const [passwordRules, setPasswordRules] = useState<IPasswordSecurity>({});
  const [passwordRegexp, setPasswordRegexp] = useState<RegExp>(generatePasswordRegExp({}));
  const [getFilialData, { isSuccess: filialDataSuccess, data: filialData }] =
    useLazyGetFilialsInfoCommonQuery();

  const [fetchAddUser, { data, error, isError, isSuccess, isLoading }] = useAddMutation();

  useEffect(() => {
    if (currentUser && isSaaS(Settings)) {
      checkCanCreate({ Customerid: currentUser?.Customer.Id }).then(() => {
        setIsRequestCompleted(true);
      });
    }
  }, [currentUser]);

  //check above dependency

  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings?.ModuleUrls
  ) as ModuleUrl[];

  const paymentModule = Array.isArray(modules)
    ? modules.find((module) => module.Name === 'Payments')
    : undefined;

  const allValidateScheme = z.object({
    Credentials: validateSchemeCreateCredentials(
      t,
      passwordRegexp,
      passwordRules,
      isPasswordEnabled,
      generatePasswordPolicyString
    ),
    Common: validateSchemeEditUserInfo(t),
    Customer: validateSchemeCustomer(t),
    Positions: validateSchemePositions(t),
    Status: validateSchemeStatus(t)
  });

  const quotaValidationScheme = z.object({
    Quota: validateSchemeDataStorageQuota(t)
  });

  const form = useForm<IUserInfo>({
    initialValues: {
      ...initForm,
      Customer: {
        Id: currentUser?.Customer?.Id ?? null,
        Name: currentUser?.Customer?.Title ?? null
      },
      Credentials: {
        ...initForm.Credentials,
        isSaas: isSaaS(Settings)
      }
    },
    validateInputOnChange: true,
    validate: (values: IUserInfo): FormErrors => getValidateErrors(allValidateScheme, values)
  });

  useEffect(() => {
    if (form.values.Customer?.Id) {
      getFilialData({ id: form.values.Customer?.Id, extended: true });
    }
  }, [form.values.Customer?.Id, getFilialData]);

  useEffect(() => {
    if (form.values.Credentials?.isCreateEmail) {
      setPasswordRules(defaultMailServerPasswordOptions);
    } else {
      if (filialDataSuccess && filialData) {
        setPasswordRules(filialData.Settings?.PasswordSecurity || {});
      }
    }
  }, [filialDataSuccess, filialData, form.values.Credentials?.isCreateEmail]);

  useEffect(() => {
    if (passwordRules) {
      if (form.values.Credentials?.isCreateEmail) {
        const regexp = generatePasswordRegExp({ options: defaultMailServerPasswordOptions });
        setPasswordRegexp(regexp);
      } else {
        const regexp = generatePasswordRegExp({ options: passwordRules });
        setPasswordRegexp(regexp);
      }
    }
  }, [passwordRules]);

  const handleSubmit = async (values: IUserInfo) => {
    const quotaErrors = getValidateErrors(quotaValidationScheme, values);
    const isValidQuota = !Object.keys(quotaErrors).length;
    if (isValidQuota) {
      await fetchAddUser({
        ...values,
        Credentials: {
          ...values.Credentials,
          isCreateEmail:
            values.Credentials.Email && values.Credentials.isCreateEmail && !isSaaS(Settings)
              ? values.Credentials.isCreateEmail
              : false
        },
        Quota: !values?.Quota?.Id ? null : values.Quota
      });
    } else {
      form.setErrors(quotaErrors);
    }
  };

  useEffect(() => {
    universalNotification({
      error: error,
      isError: isError,
      isSuccess: isSuccess,
      successTitle: t('profile.success.userLogin', {
        login: isSaaS(Settings) ? data?.Credentials?.Email : data?.Credentials?.Login
      }),
      successMessage: t('profile.success.successCreated')
    });

    if (isSuccess && data) {
      setIsCreateModal(false);
      clearApiCacheByTags(usersApi, ['Users']);
    }
  }, [data, error, isError, isSuccess]);

  const initialValues: ICreateUserContext = {
    form,
    handleSubmit
  };

  const closeSuccesModal = () => {
    setIsCreateModal(false);
    modalClose();
    form.reset();
  };

  const createAnotherUser = () => {
    form.reset();
    setIsCreateModal(true);
    setIsPasswordEnabled(false);
    setPreview('');
  };

  return (
    <Modal
      size="900px"
      opened={opened}
      onClose={modalClose}
      title={isCreateModal ? t('profile.createUser') : t('profile.successCreated')}
      scrollAreaComponent={ScrollArea.Autosize}
      zIndex={1300}
    >
      {isCreateModal ? (
        <>
          {!isRequestCompleted && isSaaS(Settings) ? (
            <LoadingOverlay visible={true} overlayProps={{ blur: 10 }} />
          ) : (canCreateUserData?.IsCanAddUser && SuccessCheckCreateUser) || !isSaaS(Settings) ? (
            <CreateUserContext.Provider value={initialValues}>
              <form onSubmit={form.onSubmit(handleSubmit)}>
                <Sections
                  isLoading={isLoading}
                  modalClose={modalClose}
                  isPasswordEnabled={isPasswordEnabled}
                  setIsPasswordEnabled={setIsPasswordEnabled}
                  previewAvatar={preview}
                  setPreviewAvatar={setPreview}
                />
              </form>
            </CreateUserContext.Provider>
          ) : (
            <Box className={classes.modalContainer}>
              <MessageContainer
                icon={iconUser}
                description={t('profile.error.createLimit')}
                buttonText={t('profile.error.createLimitButton')}
                redirect={paymentModule?.Url}
              />
            </Box>
          )}
        </>
      ) : (
        <SuccessUserModal
          modalClose={() => closeSuccesModal()}
          userInfo={data}
          createUser={() => createAnotherUser()}
          avatar={preview}
        />
      )}
    </Modal>
  );
};
