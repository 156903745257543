import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useLazyGetUsersQuery } from '@/entities/admin-app/users/api';
import { universalNotification } from '@/lib/utils/notification';
import { useBulkChangeFilialMutation } from '@/entities/admin-app/user/api';
import { IRowData } from '@/components/simple-table/types';
import { useAppSelector } from '../redux/redux';
import { useActions } from '../redux/action';
import { useTranslation } from 'react-i18next';

export const useChangeFilial = (
  setRowData: Dispatch<SetStateAction<IRowData[]>>,
  selection: number[]
) => {
  const [fetchChangeFilial, resultChangeFilial] = useBulkChangeFilialMutation();
  const [fetchGetUsers] = useLazyGetUsersQuery();
  const { filters } = useAppSelector((state) => state.users);
  const { setUsers } = useActions();
  const { t } = useTranslation();

  const changeFilial = useCallback(
    async (selection: number[], CustomerId: number) => {
      const numbersSelection = selection?.map((item) => Number(item)) ?? [];

      try {
        const res = await fetchChangeFilial({
          UserIds: numbersSelection,
          CustomerId: CustomerId
        });

        if (!Object.keys(res).includes('error')) {
          const { data } = await fetchGetUsers({
            Count: filters.Offset >= 20 ? filters.Offset + 20 : 20,
            Offset: 0,
            SortOrder: filters.SortOrder,
            SortField: filters.SortField,
            Filters: filters.Filters,
            Query: filters.Query
          });
          if (setRowData && data) {
            setUsers(data);
            setRowData(data.Users);
          }
        }
      } catch (err: any) {
        console.warn('fetchGetUsers ERROR', err);
      }
    },
    [selection]
  );

  useEffect(() => {
    const { isSuccess, isError, error } = resultChangeFilial;
    const _errorStatus = (error as any)?.data;

    universalNotification({
      isSuccess: isSuccess,
      isError: isError,
      error: _errorStatus,
      successTitle: t('filials.successUpdate')
    });
  }, [resultChangeFilial]);

  return { changeFilial };
};
