import { ActionIcon, Box, Button, SimpleGrid, TextInput } from '@mantine/core';
import { IconCircleX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useStyles } from '@/containers/pages/user/components/sections/linked-emails/styles';
import { ILinkedEmail } from '@/entities/admin-app/email';

interface Props {
  email: ILinkedEmail;

  changePassword(email: ILinkedEmail): void;

  deleteEmailItem(email: ILinkedEmail): void;
}

export const EmailItem = (props: Props) => {
  const { email, changePassword, deleteEmailItem } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const isVisibleChangeButton = email.Id === 0 || !email.IsIntegrated;

  return (
    <SimpleGrid className={classes.listRow} cols={{ base: 1, xs: 3 }}>
      <TextInput readOnly value={email.Email} />
      {!isVisibleChangeButton && (
        <Button
          mt={24}
          className={classes.changePasswordButton}
          size="sm"
          variant="outline"
          onClick={() => changePassword(email)}
        >
          {t('profile.changePassword')}
        </Button>
      )}
      <Box className={classes.removeButton}>
        <ActionIcon onClick={() => deleteEmailItem(email)}>
          <IconCircleX size={32} color="#667085" />
        </ActionIcon>
      </Box>
    </SimpleGrid>
  );
};
