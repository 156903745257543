import {
  CalendarExtraIcon,
  ContactsIcon,
  DrawIcon,
  DriveIcon,
  GearIcon,
  inviteUserIcon,
  MailIcon,
  PagesIcon,
  ProjectsIcon,
  SendIcon,
  ShieldIcon,
  TimezonesIcon,
  UsersIcon
} from '@assets/icons';
import { ROUTES } from '@/containers/routes/constants';
import { INavbarMenu } from '@/mock-data/navbar-menu';
import { useTranslation } from 'react-i18next';

export const useGetNavMenuItems = () => {
  const { t } = useTranslation();
  const filialCommonNavItems: INavbarMenu[] = [
    {
      label: t('filials.settings.general'),
      icon: GearIcon,
      value: ROUTES.filials.edit.pages.common.settings
    },
    {
      label: t('filials.settings.auth'),
      icon: UsersIcon,
      value: ROUTES.filials.edit.pages.auth.ldap
    },
    {
      label: t('profile.safety'),
      icon: ShieldIcon,
      value: ROUTES.filials.edit.pages.common.security
    },
    // { label: 'Настройка SMTP', icon: MailIcon, value: ROUTES.filials.edit.pages.common.smtp },
    {
      label: t('servers.title'),
      icon: MailIcon,
      value: ROUTES.filials.edit.pages.common.mailServers
    },
    {
      label: t('filials.settings.linkSettings'),
      icon: SendIcon,
      value: ROUTES.filials.edit.pages.common.mailingSetup
    },
    {
      label: t('register.title'),
      icon: inviteUserIcon,
      value: ROUTES.filials.edit.pages.auth.invite
    },
    {
      label: t('profile.retentionPolicy'),
      icon: DriveIcon,
      value: ROUTES.filials.edit.pages.common.diskSpace
    },
    {
      label: t('profile.timezones'),
      icon: TimezonesIcon,
      value: ROUTES.filials.edit.pages.common.timezone
    }
  ];

  const filialModulesNavItems: INavbarMenu[] = [
    {
      label: t('filials.mail'),
      icon: MailIcon,
      value: ROUTES.filials.edit.pages.modules.email
    },
    {
      label: t('filials.calendar'),
      icon: CalendarExtraIcon,
      value: ROUTES.filials.edit.pages.modules.calendar
    },
    { label: t('filials.draw'), icon: DrawIcon, value: ROUTES.filials.edit.pages.modules.draw },
    {
      label: t('filials.contacts'),
      icon: ContactsIcon,
      value: ROUTES.filials.edit.pages.modules.contacts
    },
    {
      label: t('filials.projects'),
      icon: ProjectsIcon,
      value: ROUTES.filials.edit.pages.modules.projects
    },
    {
      label: t('filials.pages'),
      icon: PagesIcon,
      value: ROUTES.filials.edit.pages.modules.pages
    }
  ];

  return { filialCommonNavItems, filialModulesNavItems };
};
