import { resetAllApiState } from '@/entities/helpers';
import { FetchToLogin } from './types';
import { useNavigate } from 'react-router-dom';
import { useLazyLoginQuery } from '@/entities/admin-app/auth/api';
import { useLazySettingsQuery } from '@/entities/admin-app/user/api';
import { useActions } from '@/hooks/redux/action';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@/hooks/redux/redux';
import { redirectIfAuth } from '@/utils/authRedirect';

export const useFetchToLogin = () => {
  const navigate = useNavigate();
  const [fetchLogin] = useLazyLoginQuery();
  const [fetchSettings] = useLazySettingsQuery();
  const { setUserInfo, setUserTokens } = useActions();
  const { Settings } = useAppSelector((state) => state.userInfo);

  return async ({ values, from }: FetchToLogin) => {
    const response = await fetchLogin(values);
    const error = response.error as { status: number } | undefined;

    if (!error) {
      if (response.data) {
        if (import.meta.env.DEV && import.meta.env.VITE_MOB) {
          const data: any = response.data;
          const date = new Date();
          date.setDate(date.getDate() + 1);
          document.cookie = `AuthToken=${data?.Tokens?.AuthToken}; expires=${date.toISOString}; path=/`;
          localStorage.setItem('AuthToken', data?.Tokens?.AuthToken);
        }

        const requestParams = new URL(document.location.href).searchParams;
        const returnUrl = requestParams.get('returnUrl');
        if (returnUrl) {
          window.location.href = returnUrl;
          return;
        }
        redirectIfAuth();

        resetAllApiState();
        navigate(from, { replace: true });
        setUserInfo(response.data.User);
        setUserTokens(response.data.Tokens);
        if (isSaaS(Settings)) {
          localStorage.setItem('isFirstLogin', 'true');
        }
      }
      //we have no page refresh after login => we have to call settings here
      await fetchSettings();
    }
  };
};
