import { FC, useContext } from 'react';
import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconBan, IconChevronDown, IconCircleCheck } from '@tabler/icons';
import { userStatusesOptions } from '@/containers/pages/user/consts/options';
import { useStyles } from './styles';
import { EditUserInfoContext } from '@/containers/pages/user/feature/edit-user';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { UserStatusEnum } from '@/types/enums/user';
import { useTranslation } from 'react-i18next';

export interface ISelectProps {
  label?: string;
  placeholder: string;
  value?: number | null;
  form?: UseFormReturnType<any>;
  fieldName: string;
  disabled?: boolean;
  editUser?: boolean;
  className?: string;
  description?: string;
}

export const StatusSelect: FC<ISelectProps> = ({
  label,
  placeholder,
  value,
  form,
  fieldName,
  disabled,
  editUser,
  className,
  description
}) => {
  const { t } = useTranslation();
  const active = value === UserStatusEnum.Active;
  const blocked = value === UserStatusEnum.Ban;
  const { classes, cx } = useStyles({ blocked });
  const { saveUpdate } = useUpdateInfo();

  const { currentId } = useContext(EditUserInfoContext);

  const handleChangeStatus = async (query: string | null) => {
    if (editUser) {
      const userStatus = query ? Number(query) : null;
      if (userStatus !== null) {
        await saveUpdate({
          Type: ProfileUpdateTypeEnum.Status,
          Data: { [ProfileSectionEnum.Status]: Number(query), Id: currentId }
        });
      }
    } else {
      form?.setFieldValue(fieldName, query ? Number(query) : null);
    }
  };

  return (
    <Select
      data-cy="statusSelect"
      comboboxProps={{ zIndex: 1400 }}
      data={userStatusesOptions(t)}
      required
      disabled={disabled}
      rightSectionWidth={30}
      rightSection={<IconChevronDown size="1rem" />}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      label={label}
      value={value?.toString() || ''}
      placeholder={placeholder}
      onChange={handleChangeStatus}
      error={form?.getInputProps(fieldName).error}
      className={cx(className, {
        [classes.blue]: active,
        [classes.red]: blocked,
        [classes.selector]: editUser
      })}
      description={description}
      leftSection={
        editUser ? (
          active ? (
            <IconCircleCheck size="1rem" />
          ) : (
            <IconBan size="1rem" color="#C23939" />
          )
        ) : (
          ''
        )
      }
    />
  );
};
