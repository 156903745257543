import { Badge, Box, Button, SimpleGrid, ActionIcon } from '@mantine/core';
import { useStyles } from './styles';
import { IconPlus, IconX } from '@tabler/icons';
import { useState } from 'react';
import { themeColors } from '@/theme/colors';
import { IGroup } from '@/entities/admin-app/user';
import { IListItem } from '@/types';

import { SelectGroupModal } from '@components/modal/select-group';
import { UseFormReturnType } from '@mantine/form';
import { IRole } from '@/entities/admin-app/directories';
import { useTranslation } from 'react-i18next';

interface IRolesSectionProps {
  fieldName: string;
  form: UseFormReturnType<any>;
  className?: string;
  Roles: IRole[] | null;
  isLimitedAccess?: boolean;
  isEditPage?: boolean;
  disabled?: boolean;
}

export const Roles = ({
  form,
  fieldName,
  Roles,
  isLimitedAccess,
  isEditPage,
  disabled
}: IRolesSectionProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [addUserInGroupModal, setAddUserInGroupModal] = useState<boolean>(false);

  const [selectedGroup, setSelectedGroup] = useState<IListItem>({} as IListItem);

  const handleAddRole = (roles: IGroup[]) => {
    const filtered = roles?.filter((obj) => !Roles?.some((item) => item.Id === obj.Id));
    filtered?.map((role) => {
      form.insertListItem(fieldName, { ...role, IsRemovable: true });
    });
    setAddUserInGroupModal(false);
  };

  const closeAddUserInGroupModal = () => {
    setAddUserInGroupModal(false);
  };

  const openAddUserInGroupModal = () => {
    setAddUserInGroupModal(true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {isLimitedAccess ? null : (
          <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
            <Button
              className={classes.addBtn}
              size="sm"
              variant="outline"
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={openAddUserInGroupModal}
              mb="16px"
              data-cy="addRoleBtn"
              disabled={disabled}
            >
              {t('profile.addRole')}
            </Button>
          </SimpleGrid>
        )}

        <Box>
          {Roles?.map((item, index) => {
            return (
              <Badge
                className={classes.badge}
                key={item.Id}
                variant="outline"
                rightSection={
                  !isLimitedAccess && (!isEditPage || item.IsRemovable) ? (
                    <ActionIcon
                      data-id={item.Id}
                      size={16}
                      variant="transparent"
                      onClick={() => form.removeListItem(`${fieldName}`, index)}
                    >
                      <IconX size={16} color={themeColors.brandGray[0]} />
                    </ActionIcon>
                  ) : null
                }
                data-cy={`${item.Name}`}
              >
                {item.Name}
              </Badge>
            );
          })}
        </Box>
      </Box>
      <SelectGroupModal
        isOpened={addUserInGroupModal}
        title={t('profile.addRole')}
        onClose={closeAddUserInGroupModal}
        setSelectedGroup={setSelectedGroup}
        selectedGroup={selectedGroup}
        setGroups={handleAddRole}
        groups={Roles || []}
      />
    </Box>
  );
};
