import { IFilterConditionItem } from '@/types/api';

export const convertToFilterConditions = (
  activeFilters: Record<number, number[] | Date[]>
): IFilterConditionItem[] => {
  const filterObjects: IFilterConditionItem[] = [];

  for (const [key, value] of Object.entries(activeFilters)) {
    const filterObject: IFilterConditionItem = {
      Type: Number(key)
    };

    if (key === '11' || key === '12' || key === '13') {
      const [from, to] = value as Date[];
      filterObject.DateRange = {
        From: from?.toISOString(),
        To: to.toISOString()
      };
    } else if (key === '15') {
      const [from, to] = value as number[];
      filterObject.NumRange = {
        From: from,
        To: to
      };
    } else {
      filterObject.NumValues = value as number[];
    }

    filterObjects.push(filterObject);
  }

  return filterObjects;
};
