import React from 'react';
import { IconPlus } from '@tabler/icons';
import { Button, Text } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';
import { CreateUserModal } from '@/containers/pages/user/feature/create-user';

export const CreateButton = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 1200px)');

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        size="md"
        leftSection={<IconPlus />}
        type="button"
        className={classes.createButton}
        onClick={open}
        data-cy="createNewUserButton"
      >
        <Text fw="600">{isMobile ? '' : t('create')}</Text>
      </Button>
      {opened && (
        <CreateUserModal
          opened={opened}
          modalClose={() => {
            close();
          }}
        />
      )}
    </>
  );
};
