import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  moduleItem: {
    padding: '4px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#A0B0C31A',
      borderRadius: '8px'
    }
  }
}));
