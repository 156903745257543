import { Text, Stack, Flex, Box } from '@mantine/core';
import { IconDisc, IconInfoCircle } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { GB, formatAmount } from '../../utils';
import { useStyles } from './styles';
import { Modal } from '@components/modal';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';

interface isExpiredPaymentProps {
  usedSpace?: number;
  link?: string;
}

export const ExpiredPayment: FC<isExpiredPaymentProps> = ({ usedSpace, link }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [usedAmmount, setUsedAmmount] = useState<string>('');

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getUsedSpace = (amount: number) => {
    const currentAmount = amount / GB;
    return formatAmount(currentAmount);
  };

  useEffect(() => {
    if (!usedSpace) {
      setUsedAmmount('0');
    }
    if (usedSpace) {
      setUsedAmmount(getUsedSpace(usedSpace));
    }
  }, [usedSpace]);

  return (
    <Box className={classes.boxContainer}>
      <Stack gap="8px">
        <Flex direction="column" gap="8px" py="8px">
          <Text fz="sm" fw="500" sx={{ textAlign: 'center' }}>
            {t('filials.diskSpace.expiredPayment')}
          </Text>
          <Text sx={{ textAlign: 'center' }} fz="xs" c="#848E98">
            {t('filials.diskSpace.warning')}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center">
          <Box className={classes.spaceBox}>
            <IconDisc size={20} color="#FF6B6B" stroke={1.5} />
            <Text ml="2px" c="#FF6B6B" fz="xs">
              {t('filials.diskSpace.usedSpace')} {usedAmmount}
            </Text>
          </Box>
          <Box className={classes.infoBox} onClick={() => setOpenModal(true)}>
            <IconInfoCircle size={14} color="#FF6B6B" stroke={1.5} />
          </Box>
        </Flex>
      </Stack>
      <Modal
        size="lg"
        title={t('filials.expiredTitle')}
        opened={openModal}
        onClose={handleCloseModal}
      >
        <Box className={classes.modalRoot}>
          <Box className={classes.modalContainer}>
            <Stack gap="10px" className={classes.infoGroup}>
              <Text>{t('filials.expiredInfo1')}</Text>
              <Text fw="500" c="#343A40">
                {t('filials.expiredSubtitle1')}
              </Text>
              <Text>{t('filials.expiredInfo2')}</Text>
              <Text fw="500" c="#343A40">
                {t('filials.expiredSubtitle2')}
              </Text>
              <Text>{t('filials.expiredInfo3')}</Text>
            </Stack>
            <Flex w="100%" justify="flex-end">
              <Button
                mt="16px"
                fw="500"
                component={Link}
                target="_self"
                to={link ?? '#'}
                radius="8px"
              >
                {t('filials.diskSpace.activateTariff')}
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
