import { Stack } from '@mantine/core';
import { LicensesSearch } from '@components/licenses/search';
import { LicensesList } from '@components/licenses/list';

import {
  LicenseItem,
  selectActivationParams,
  selectDeactivationParams,
  useActivateLicenseMutation,
  useDeactivateLicenseMutation,
  useGetLicensesQuery
} from '@/entities/admin-app/licenses';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export interface LicensesProps {
  withSearch?: boolean;
}

export const Licenses = ({ withSearch = false }: LicensesProps) => {
  const { t } = useTranslation();
  const { setLicenseActivationParams, setLicenseDeactivationParams } = useActions();

  const activationParams = useAppSelector(selectActivationParams);
  const deactivationParams = useAppSelector(selectDeactivationParams);

  const activated = useGetLicensesQuery(activationParams);
  const deactivated = useGetLicensesQuery(deactivationParams);

  const [activate] = useActivateLicenseMutation();
  const [deactivate] = useDeactivateLicenseMutation();

  const handlePageChange = (page: number, isActivated = true) =>
    isActivated
      ? setLicenseActivationParams({
          ...activationParams,
          offset: activationParams.count * (page - 1)
        })
      : setLicenseDeactivationParams({
          ...deactivationParams,
          offset: deactivationParams.count * (page - 1)
        });

  const handleActionClick = async (item: LicenseItem, isActivated = true) => {
    if (isActivated) {
      await deactivate(item.Id);
      notifications.show({ message: t('license.deactivateLicense'), color: 'green' });
    } else {
      await activate({
        InstallationGuid: item.InstallationGuid,
        MachineHostname: item.MachineHostname,
        MachineId: item.MachineId,
        MachineIp: item.MachineIp,
        OperatingSystem: item.OperatingSystem,
        ProductType: item.ProductType,
        ProductVersion: item.ProductVersion,
        GroupId: item.GroupId,
        SubscriptionId: item.SubscriptionId
      });
      notifications.show({ message: t('license.activateLicense'), color: 'green' });
    }
  };

  return (
    <Stack w="100%">
      {withSearch && <LicensesSearch />}
      <Stack gap="sm">
        <LicensesList
          isActive
          isLoading={activated.isLoading}
          data={activated.data?.Items ?? []}
          page={activationParams.offset / activationParams.count + 1}
          total={Math.ceil((activated.data?.TotalCount ?? 0) / 2)}
          onPageChange={handlePageChange}
          onActionClick={handleActionClick}
        />
        <LicensesList
          isActive={false}
          isLoading={deactivated.isLoading}
          data={deactivated.data?.Items ?? []}
          page={deactivationParams.offset / deactivationParams.count + 1}
          total={Math.ceil((deactivated.data?.TotalCount ?? 0) / 2)}
          onPageChange={(page) => handlePageChange(page, false)}
          onActionClick={(item) => handleActionClick(item, false)}
        />
      </Stack>
    </Stack>
  );
};
