import { Badge, Flex, Text } from '@mantine/core';
import { FC } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import { useNavigate } from 'react-router-dom';
import { IconAlertTriangleFilled, IconCircleCheckFilled } from '@tabler/icons-react';

export interface ItemProps {
  name: string;
  link?: string;
  openModal?: () => void;
  valid?: boolean;
  badge?: number;
}

export interface IListItemProps {
  withCheck: boolean;
  items: ItemProps[];
}

export const ListItem: FC<IListItemProps> = ({ withCheck, items }) => {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const handleRedirect = async ({ link, name, openModal }: ItemProps) => {
    if (link) {
      scrollTo(0, 0);
      navigate(link);
    }
    if (openModal) {
      openModal();
    }

    if (name === t('filialSecurity.passwordSecurity') && link) {
      const checkElement = () => {
        const element = document.querySelector(
          `[data-section=${FilialInfoPanelsEnum.passwordLimit}]`
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          setTimeout(checkElement, 100);
        }
      };
      setTimeout(checkElement, 100);
    }
  };

  return (
    <>
      {items.map((item: ItemProps, index: number) => {
        return (
          <Flex key={index} justify="space-between" align="center">
            <Text
              onClick={() => handleRedirect(item)}
              className={cx(classes.itemName, {
                [classes.interactiveItemName]: Boolean(item.link || item.openModal)
              })}
            >
              {item.name}
            </Text>
            {withCheck ? (
              item.valid ? (
                <Flex sx={{ color: '#51CF66' }}>
                  <IconCircleCheckFilled size={22} />
                </Flex>
              ) : (
                <Flex sx={{ color: '#FA5252' }}>
                  <IconAlertTriangleFilled fill="red" size="22" />
                </Flex>
              )
            ) : null}
            {item.badge ? (
              <Badge
                classNames={{ root: classes.badgeRoot, label: classes.badgeInner }}
                variant="filled"
              >
                {item.badge}
              </Badge>
            ) : null}
          </Flex>
        );
      })}
    </>
  );
};
