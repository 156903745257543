import React, { FC, useContext } from 'react';
import { ActionIcon, Box } from '@mantine/core';
import { UserInfoMainContext } from '../../panels';
import { IconArrowLeft } from '@tabler/icons';
import { Action } from '@/containers/pages/users/components/table/action';
import { useActions } from '@hooks/redux/action';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/containers/routes/constants';

export const DrawerHeader: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setRowData, userInfoCommon } = useContext(UserInfoMainContext);
  const loginAs = userInfoCommon?.Credentials?.Login;

  const { setSelectedUserId } = useActions();

  const goBack = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedUserId(0);
    navigate(ROUTES.users.path);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <ActionIcon onClick={goBack} size="md" data-cy="backArrow" className={classes.backArrow}>
        <IconArrowLeft color="#667085" />
      </ActionIcon>
      {userInfoCommon ? (
        <Action
          loginAsUser={loginAs}
          hasIconEye={false}
          setRowData={setRowData}
          item={{
            ...userInfoCommon,
            Id: userInfoCommon?.Id,
            Email: userInfoCommon?.Credentials?.Email
          }}
        />
      ) : null}
    </Box>
  );
};
