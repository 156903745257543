import { useMemo } from 'react';

import { useGetDefaultRolesFiltersQuery } from '@/entities/admin-app/roles/api';
import { IFilterMenu } from '@/types/api';
import { filtersLabelsKeys } from '@/entities/admin-app/constants';
import { IFiltersEnumType } from '@/types/enums';
import { useTranslation } from 'react-i18next';
import type { DefaultFilter } from '@/entities/admin-app/roles';

const transformFilters = (data: DefaultFilter[], t: (key: string) => string): IFilterMenu[] => {
  return data.map((item) => ({
    ...item,
    label:
      item.Type in IFiltersEnumType
        ? t(`filters.${filtersLabelsKeys[item.Type]}`)
        : t('filters.title'),
    Items: item.Items?.map((filterItem) => ({ ...filterItem, checked: false })),
    hideSearch: item.Type === IFiltersEnumType.RoleTypeFilter ? true : false
  }));
};

export const useGetRolesFilters = () => {
  const { t } = useTranslation();
  const {
    data: defaultFiltersData,
    isSuccess: defaultFiltersSuccess,
    isFetching
  } = useGetDefaultRolesFiltersQuery(20);

  const filtersMenuList = useMemo(() => {
    return defaultFiltersSuccess ? transformFilters(defaultFiltersData || [], t) : [];
  }, [defaultFiltersData, defaultFiltersSuccess, t]);

  return { filtersMenuList, isFetching };
};
