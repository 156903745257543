import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueryTableV1 } from '@/types/api';
import { IFilialsInitialFilters } from '@/types/enums/filials';
import { IFilialsTableData, IFilialsData, IFilialsInfoCommon, Timezone } from './types';

interface IFilials {
  filial: IFilialsInfoCommon | null;
  filials: IFilialsTableData[];
  total: number;
  filters: IQueryTableV1<IFilialsInitialFilters>;
  selectedFilialsId: number;
  selected: number[];
}

const initialState: IFilials = {
  filial: null,
  filials: [],
  total: 0,
  filters: {
    Count: 10,
    Offset: 0,
    Query: ''
  },
  selectedFilialsId: 0,
  selected: []
};

export const filialsSlice = createSlice({
  name: 'filials',
  initialState,
  reducers: {
    setFilial(state, action: PayloadAction<IFilialsInfoCommon>) {
      state.filial = action.payload;
    },
    setFilials(state, action: PayloadAction<IFilialsData>) {
      state.filials = action.payload.Items;
      state.total = action.payload.TotalCount;
    },
    setFilialsTableFilters(state, action: PayloadAction<IQueryTableV1<IFilialsInitialFilters>>) {
      state.filters = action.payload;
    },
    setSelectedFilialsId(state, action: PayloadAction<number>) {
      state.selectedFilialsId = action.payload;
    },
    setSelectedFilialsIds(state, action: PayloadAction<number>) {
      state.selected.includes(action.payload)
        ? (state.selected = state.selected.filter((state) => state !== action.payload))
        : (state.selected = [...state.selected, action.payload]);
    },
    setSelectedAllFilials(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    setFilialTimezone(state, action: PayloadAction<Timezone>) {
      if (state?.filial?.Settings) {
        state.filial.Settings.TimeZone = action.payload;
      }
    },
    clearFilialsSelected(state) {
      state.selected = [];
    }
  }
});

export const filialsActions = filialsSlice.actions;
export const filialsReducer = filialsSlice.reducer;
