import TimezoneSelect from '@/components/TImezoneSelect';
import { SectionTemplate } from '@/containers/pages/user/components/section-template';
import { IGlobalSettings, ITimezone } from '@/entities/admin-app/auth';
import { useUpdateSettingMutation } from '@/entities/admin-app/user/api';
import { useActions } from '@/hooks/redux/action';
import { useUpdateInfo } from '@/hooks/users/useUpdateUser';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { ProfileSectionEnum, ProfileUpdateTypeEnum } from '@/types/enums/users';
import { Button, Checkbox, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isCurrentUser: boolean;
  userInfoCommon: any;
  Timezone: ITimezone | undefined;
  currentId: number;
  Settings: IGlobalSettings | undefined;
}

const TimezoneForm = ({ isCurrentUser, userInfoCommon, Timezone, currentId, Settings }: IProps) => {
  const { t } = useTranslation();
  const { setTimezoneSettings } = useActions();
  const [updateInfo, { isLoading }] = useUpdateSettingMutation();
  const { saveUpdate, isLoading: isLoadingUserUpdate } = useUpdateInfo();

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      checkbox: isCurrentUser
        ? Timezone?.SecondEnabled
        : Boolean(userInfoCommon?.TimeZone?.SecondEnabled),
      mainTimezone: isCurrentUser
        ? String(Timezone?.Id) ?? '0'
        : String(userInfoCommon?.TimeZone?.Id) ?? '0',
      secondTimezone: isCurrentUser
        ? String(Timezone?.SecondId) ?? '0'
        : String(userInfoCommon?.TimeZone?.SecondId) ?? '0'
    }
  });
  useEffect(() => {
    if (userInfoCommon.TimeZone) {
      form.setValues({
        checkbox: userInfoCommon.TimeZone.SecondEnabled,
        mainTimezone: String(userInfoCommon.TimeZone.Id) ?? '0',
        secondTimezone: String(userInfoCommon.TimeZone.SecondId) ?? '0'
      });
    }
  }, [userInfoCommon]);

  const handleSubmit = async () => {
    const data = form.getValues();

    if (!isCurrentUser) {
      if (!userInfoCommon) return;
      await saveUpdate({
        Type: ProfileUpdateTypeEnum.TimeZone,
        Data: {
          [ProfileSectionEnum.TimeZone]: {
            ...userInfoCommon.TimeZone,
            Id: (Number(data.mainTimezone) as number) || 0,
            SecondEnabled: (data.checkbox as boolean) || false,
            SecondId: (Number(data.secondTimezone) as number) || 0
          },
          Id: currentId
        }
      });

      return;
    }

    if (!Settings) return;
    const res = await updateInfo({
      ...Settings,
      Timezone: {
        ...Settings.Timezone,
        Id: (Number(data.mainTimezone) as number) ?? 0,
        SecondEnabled: (data.checkbox as boolean) ?? false,
        SecondId: (Number(data.secondTimezone) as number) ?? 0
      }
    });
    if ('error' in res) {
      const codeError = extractErrorCode(res.error);
      showNotification({
        title: generateErrorMessage(codeError),
        message: '',
        autoClose: true,
        color: 'red',
        style: { zIndex: 1003 }
      });
    } else {
      setTimezoneSettings({
        ...Settings?.Timezone,
        Id: Number(data.mainTimezone) ?? 0,
        SecondEnabled: data.checkbox ?? false,
        SecondId: Number(data.secondTimezone) ?? 0
      });
      showNotification({
        title: t('saved'),
        message: '',
        autoClose: true,
        color: 'green',
        style: { zIndex: 1003 }
      });
    }
    form.resetDirty();
  };
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SectionTemplate title={t('profile.timezones')}>
        <Checkbox
          styles={{ label: { cursor: 'pointer' }, labelWrapper: { width: 'auto !important' } }}
          fz="12px"
          label={t('profile.timezoneCheckboxLabel')}
          mb={12}
          {...form.getInputProps('checkbox', { type: 'checkbox' })}
        />

        <TimezoneSelect
          value={form.getInputProps('mainTimezone').value}
          {...form.getInputProps('mainTimezone')}
        />

        <Text mb={24} w="90%" fz={14} c="#667085">
          {t('profile.timezoneInfo')}
        </Text>

        {form.getInputProps('checkbox').value && (
          <TimezoneSelect
            value={form.getInputProps('secondTimezone').value}
            label={t('profile.additionalTimezone')}
            {...form.getInputProps('secondTimezone')}
          />
        )}

        <Button
          w={200}
          loading={isLoading || isLoadingUserUpdate}
          h={36}
          type="submit"
          disabled={!form.isDirty()}
        >
          {t('save')}
        </Button>
      </SectionTemplate>
    </form>
  );
};

export default TimezoneForm;
