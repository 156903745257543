import { useAppSelector } from '@/hooks/redux/redux';
import { Select } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: string;
  label?: string;
}

const TimezoneSelect = ({ value, ...rest }: IProps) => {
  const { t } = useTranslation();

  const { Timezones } = useAppSelector((state) => state.commonSettings.TimezoneSettings);

  const data = useMemo(() => {
    return Object.values(Timezones).sort((a, b) => a.utcOffsetMinutes - b.utcOffsetMinutes);
  }, [Timezones]);

  return (
    <Select
      w="100%"
      miw={352}
      maw={380}
      styles={{
        label: { color: '#667085', fontSize: 12 }
      }}
      radius="md"
      label={t('profile.timeZone')}
      placeholder={t('profile.selectTimeZone')}
      data={data}
      value={value}
      mb={25}
      rightSection={<IconChevronDown stroke={1.5} />}
      searchable
      {...rest}
    />
  );
};

export default TimezoneSelect;
