import { useState, useEffect, useMemo } from 'react';
import { ComboboxItem } from '@mantine/core';
import { IQuota } from '@/entities/admin-app/quotas';
import { IFiltersEnumType } from '@/types/enums';
import { IFilterConditionItem } from '@/types/api';
import { useLazyGetQuotasQuery } from '@/entities/admin-app/quotas/api';

export interface FilterProps {
  Count: number;
  Offset: number;
  FilterConditions?: IFilterConditionItem[];
  LoadUseCount: boolean;
}

export default function userLoadQuotas(Customer?: number | null, createUser?: boolean) {
  const [quotas, setQuotas] = useState<IQuota[]>([]);
  const [quotaKey, setQuotaKey] = useState<number>(0);
  const [defaultQuotaId, setDefaultQuotaId] = useState<string>('');

  const [filters, setFilters] = useState<FilterProps>({
    Count: 10,
    Offset: 0,
    LoadUseCount: false
  });

  const [getQuotas, { isFetching, isError, data }] = useLazyGetQuotasQuery();

  useEffect(() => {
    if (Customer) {
      getQuotas({
        FilterConditions: [
          {
            Type: IFiltersEnumType.FilialFilter,
            NumValues: [Customer]
          }
        ],
        ...filters
      });
    }
  }, [Customer, filters]);

  const total = data?.TotalCount || 0;

  const loadMore = () => {
    if (total - filters.Offset > 0) {
      setFilters({
        ...filters,
        Offset: filters.Offset + 10
      });
    }
  };

  useEffect(() => {
    if (data) {
      setQuotas(filters.Offset > 0 ? quotas.concat(data.Quotas) : data.Quotas);
      setQuotaKey(Math.random());
      if (createUser) {
        if (data.DefaultItemId) {
          setDefaultQuotaId(data.DefaultItemId.toString());
        } else {
          setDefaultQuotaId('');
        }
      }
    }
  }, [data]);

  const list = useMemo(
    () =>
      Array.isArray(quotas)
        ? quotas?.map(
            (Item: IQuota) =>
              ({ label: String(Item.name), value: Item.id.toString() } as ComboboxItem)
          )
        : [],
    [quotas]
  );

  return { list, total, isFetching, isError, quotaKey, defaultQuotaId, loadMore };
}
