import { useContext, useEffect, useState } from 'react';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { useStyles } from './styles';
import { Box, CloseIcon, Flex, Indicator, Text } from '@mantine/core';
import { UserAvatar } from '@/components/user-avatar';
import { Overlay } from '@mantine/core';
import { AvatarEditModal } from '../../../modal/avatar-edit';
import { TextFieldInput } from '@/components/fields/text-field-input';
import { Stack } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface IAvatarFioProps {
  preview: string;
  setPreview: (value: string) => void;
  disabled: boolean;
}

export const AvatarFio = ({ preview, setPreview, disabled }: IAvatarFioProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);

  const { Common } = form.values;
  const CommonFormKey = 'Common';

  const [editAvatar, setEditAvatar] = useState<boolean>(false);
  const [user, setUser] = useState<{ FirstName: string | null; SurName: string | null }>({
    FirstName: '',
    SurName: ''
  });

  useEffect(() => {
    setUser({
      FirstName: Common.FirstName || null,
      SurName: Common.SurName || null
    });
  }, [Common.FirstName, Common.SurName]);

  const handleCloseModalEditAvatar = () => {
    setEditAvatar(false);
  };

  const handleDeleteAvatar = () => {
    setPreview('');
    form.setFieldValue('AvatarId', null);
  };

  const label = preview ? (
    <ActionIcon size="md" variant="transparent" onClick={() => handleDeleteAvatar()}>
      <CloseIcon fill="#667085" />
    </ActionIcon>
  ) : null;

  return (
    <Flex w="100%" gap="md" align="center">
      <Box className={classes.avatarBox}>
        <Indicator label={label} size={13} offset={10} position="top-end" color="transparent">
          <Box
            onClick={() => {
              setEditAvatar(true);
            }}
          >
            <UserAvatar
              size="100"
              src={preview}
              user={user.FirstName || user.SurName ? user : undefined}
            />
            <Overlay className={classes.avatarOverlay}>
              <Text className={classes.overlayText}>
                {preview ? t('avatar.changeAvatar') : t('avatar.uploadPhoto')}
              </Text>
            </Overlay>
          </Box>
        </Indicator>
      </Box>
      <AvatarEditModal
        isOpened={editAvatar}
        title={t('profile.profilePhoto')}
        onClose={handleCloseModalEditAvatar}
        preview={preview}
        setPreview={setPreview}
      />
      <Stack w="100%" gap="0">
        <TextFieldInput
          fieldName={`${CommonFormKey}.SurName`}
          placeholder={t('profile.surName')}
          form={form}
          className={classes.headerInput}
          disabled={disabled}
        />
        <TextFieldInput
          fieldName={`${CommonFormKey}.FirstName`}
          placeholder={t('profile.firstName')}
          form={form}
          className={classes.headerInput}
          disabled={disabled}
        />
        <TextFieldInput
          fieldName={`${CommonFormKey}.MiddleName`}
          placeholder={t('profile.middleName')}
          form={form}
          className={classes.headerInput}
          disabled={disabled}
        />
      </Stack>
    </Flex>
  );
};
