import { useContext } from 'react';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { useStyles } from './styles';
import { Collapse, Flex, Group, Text, InputProps } from '@mantine/core';

import { useTranslation } from 'react-i18next';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';

import { IconChevronUp } from '@tabler/icons';
import { useDirection } from '@mantine/core';
import { SimpleGrid } from '@mantine/core';
import { Gender } from '@/components/fields/gender';
import { DatePicker } from '@components/fields/date-picker';
import { Address } from '@/components/fields/address';
import { ValidatePhoneInput } from '@/containers/pages/user/components/validateFields/validatePhoneInput';
import { Phone } from '@/components/fields/phone';
import { MoreInfo } from './moreInfo';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { CreatePassword } from '../password';

interface IAdditionalInfoProps {
  isPasswordEnabled: boolean;
  setIsPasswordEnabled: (value: boolean) => void;
  disabled: boolean;
}

export const AdditionalInfo = ({
  isPasswordEnabled,
  setIsPasswordEnabled,
  disabled
}: IAdditionalInfoProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);
  const { dir } = useDirection();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const { Common } = form.values;
  const CommonFormKey = 'Common';
  const isMobile = useMediaQuery('(max-width: 576px)');

  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Flex direction="column" w="100%">
      <Group className={classes.group} onClick={toggle} gap="5px">
        <Text className={classes.groupTitle}>{t('profile.additional')}</Text>
        <IconChevronUp
          className={classes.chevron}
          size={16}
          stroke={1.5}
          style={{
            transform: !opened ? `rotate(${dir === 'rtl' ? -180 : 180}deg)` : 'none'
          }}
        />
      </Group>
      <Collapse in={opened}>
        <CreatePassword
          isPasswordEnabled={isPasswordEnabled}
          setIsPasswordEnabled={setIsPasswordEnabled}
          disabled={disabled}
        />
        <Flex mb="30px" mt="20px" direction="column" gap="xs">
          <Text className={classes.sectionTitle}>{t('profile.commonInfo')}</Text>
          <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%" spacing={isMobile ? 0 : 'md'}>
            <DatePicker
              fieldName={`${CommonFormKey}.BirthDate`}
              placeholder={t('profile.chooseBirthDate')}
              label={t('profile.birthDate')}
              form={form}
              clearable={true}
              disabled={disabled}
            />
            <Gender
              fieldName={`${CommonFormKey}.Gender`}
              form={form}
              defaultValue={String(Common.Gender)}
              clearable={true}
              disabled={disabled}
            />
          </SimpleGrid>
          <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%" spacing={isMobile ? 0 : 'md'}>
            <Address
              fieldName={`${CommonFormKey}.Address`}
              label={t('profile.address')}
              placeholder={t('profile.addAddress')}
              form={form}
              disabled={disabled}
            />
            <ValidatePhoneInput
              form={form}
              mask="+7 (999) 999-99-99"
              label={t('profile.phone')}
              fieldName={`${CommonFormKey}.Phone`}
              disabled={disabled}
            />
          </SimpleGrid>
          <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
            <Phone
              label={t('profile.additionalPhone')}
              inputProps={form.getInputProps(`${CommonFormKey}.AdditionalPhone`) as InputProps}
              form={form}
              fieldName={`${CommonFormKey}.AdditionalPhone`}
              disabled={disabled}
            />
          </SimpleGrid>
        </Flex>
        {!isSaaS(Settings) && <MoreInfo disabled={disabled} />}
      </Collapse>
    </Flex>
  );
};
