import { Box, Button, Flex, Text } from '@mantine/core';
import { useStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';
import TimezoneSelect from '@/components/TImezoneSelect';
import { useForm } from '@mantine/form';
import { useAppSelector } from '@/hooks/redux/redux';
import { useEffect } from 'react';
import { useUpdateFilialTimezoneMutation } from '@/entities/admin-app/filials/api';
import { showNotification } from '@mantine/notifications';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { useActions } from '@/hooks/redux/action';

const TimezoneDivisionSection = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);
  const { TimeZone } = filial?.Settings ?? {};
  const [updateSettings, { isLoading }] = useUpdateFilialTimezoneMutation();
  const { setFilialTimezone } = useActions();
  const { Timezones } = useAppSelector((state) => state.commonSettings.TimezoneSettings);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      mainTimezone: String(TimeZone?.Id) ?? '0'
    }
  });

  useEffect(() => {
    if (TimeZone) {
      form.setValues({
        mainTimezone: String(TimeZone?.Id) ?? '0'
      });
      form.resetDirty();
    }
  }, [TimeZone]);

  const handleSubmit = async () => {
    const data = form.getValues();
    const res = await updateSettings({
      CustomerId: filial?.Id,
      Type: 9,
      Settings: {
        TimeZone: {
          Id: Number(data.mainTimezone)
        }
      }
    });

    if ('error' in res) {
      const codeError = extractErrorCode(res.error);
      showNotification({
        title: generateErrorMessage(codeError),
        message: '',
        autoClose: true,
        color: 'red',
        style: { zIndex: 1003 }
      });
    } else {
      const selectedTimezone = Timezones[data.mainTimezone];
      setFilialTimezone({
        Id: Number(data.mainTimezone),
        Name: selectedTimezone?.value,
        TzIdName: selectedTimezone.tzName,
        UtcOffset: selectedTimezone.label,
        UtcOffsetMinutes: selectedTimezone.utcOffsetMinutes
      });
      showNotification({
        title: t('saved'),
        message: '',
        autoClose: true,
        color: 'green',
        style: { zIndex: 1003 }
      });
    }
    form.resetDirty();
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex className={classes.section} align="flex-start" direction="column">
          <Text className={classes.title}>{t('profile.timezones')}</Text>
          <TimezoneSelect
            value={form.getInputProps('mainTimezone').value}
            {...form.getInputProps('mainTimezone')}
          />

          <Text className={classes.description}>{t('profile.timezoneInfoOrganization')}</Text>

          <Button
            w={200}
            loading={isLoading}
            mt={17}
            h={36}
            type="submit"
            disabled={!form.isDirty()}
          >
            {t('save')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default TimezoneDivisionSection;
