import { Stack } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconChartAreaLine } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';

export const StatisticsSection = () => {
  const { t } = useTranslation();

  const statisticsMenu: IListItemProps = {
    withCheck: false,
    items: [
      { name: t('statistics.activity'), link: ROUTES.statistics.fullPath },
      {
        name: t('statistics.disk_space'),
        link: `${ROUTES.statistics.fullPath}/${ROUTES.statistics.links.diskSpace}`
      },
      {
        name: t('statistics.audit'),
        link: `${ROUTES.statistics.fullPath}/${ROUTES.statistics.links.audit}`
      }
    ]
  };

  return (
    <CardContainer sectionName={t('navigation.statistics')} Icon={IconChartAreaLine}>
      <Stack gap="11px" sx={{ width: '100%' }}>
        <ListItem items={statisticsMenu.items} withCheck={statisticsMenu.withCheck} />
      </Stack>
    </CardContainer>
  );
};
