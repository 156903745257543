import React, { useEffect, useState } from 'react';
import { useLazyResetPasswordQuery } from '@/entities/admin-app/auth/api';
import {
  Box,
  Button,
  SimpleGrid,
  TextInput,
  UnstyledButton,
  Text,
  LoadingOverlay
} from '@mantine/core';
import { IconCopy } from '@tabler/icons';
import { Modal } from '@/components/modal';
import { useStyles } from './styles';
import { copyToClipboard } from '@/containers/pages/user/utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { universalNotification } from '@/lib/utils/notification';
import { RoleTypesEnum } from '@/types/enums/user';
import { IUserInfo } from '@/entities/admin-app/user';

interface IResetPassword {
  defaultLink?: string;
  currentId: number;
  userInfoCommon: IUserInfo;
  userId?: number;
}
export const ResetPasswordSection = ({
  defaultLink,
  currentId,
  userInfoCommon,
  userId
}: IResetPassword) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [resetLink, setResetLink] = useState<string | undefined>(defaultLink);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState<boolean>(false);

  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const [handleResetPassword, { data, isLoading, isSuccess }] = useLazyResetPasswordQuery();
  const isMobile = useMediaQuery('(max-width: 576px)');
  const notAllowedResetPassword = userInfoCommon.Roles?.some(
    (role) => role?.Type === RoleTypesEnum.SuperUser && userId !== currentId
  );

  const copyText = async () => {
    try {
      copyToClipboard(resetLink);
      setLinkCopied(true);
      universalNotification({
        isError: false,
        error: null,
        isSuccess: true,
        successTitle: t('copied')
      });
    } catch (e) {
      setLinkCopied(false);
    }
  };

  const handleReset = async () => {
    try {
      const res = await handleResetPassword({ UserId: currentId });
      universalNotification({
        error: res.error,
        isError: res.isError,
        isSuccess: res.isSuccess,
        successTitle: t('profile.resetPasswordSuccess')
      });
      if (res.isSuccess) {
        setLinkCopied(false);
      }
      setOpenResetPasswordModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setResetLink(data?.Url);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (defaultLink) {
      setResetLink(defaultLink);
    }
  }, [defaultLink]);

  const closeModal = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpenResetPasswordModal(false);
  };

  return (
    <>
      <SimpleGrid cols={isMobile ? 1 : 2} w="100%" className={classes.container}>
        <TextInput
          className={classes.linkInput}
          label={t('profile.passwordRecoveryLink')}
          placeholder={t('profile.noActiveLink')}
          rightSection={
            resetLink && (
              <IconCopy cursor="pointer" color={linkCopied ? 'teal' : 'blue'} onClick={copyText} />
            )
          }
          value={resetLink}
          readOnly={true}
          data-cy="resetPasswordLink"
        />
        <Button
          onClick={() => setOpenResetPasswordModal(true)}
          className={classes.resetPasswordButton}
          disabled={notAllowedResetPassword}
          data-cy="resetPasswordBtn"
        >
          {t('profile.resetPassword')}
        </Button>
      </SimpleGrid>
      <Modal
        zIndex={1400}
        size="400px"
        opened={openResetPasswordModal}
        onClose={() => setOpenResetPasswordModal(false)}
        title={t('profile.passwordReset')}
      >
        <Box className={classes.modalRoot}>
          <Text className={classes.modalContainer} py="md">
            {t('profile.resetPasswordAndGenerateLink')}
          </Text>
          <Box className={classes.modalFooter}>
            <Button
              size="sm"
              radius={8}
              w="49%"
              onClick={handleReset}
              data-cy="resetPasswordConfirm"
            >
              {t('profile.reset')}
            </Button>
            <UnstyledButton
              className={classes.modalCancelBtn}
              w="49%"
              onClick={closeModal}
              data-cy="resetPasswordCancel"
            >
              {t('profile.cancel')}
            </UnstyledButton>
          </Box>
        </Box>
        <LoadingOverlay visible={isLoading} />
      </Modal>
    </>
  );
};
