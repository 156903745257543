import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles((theme, _, utils) => ({
  container: {
    flexGrow: 1,
    width: `calc(100% - 580px)`,
    backgroundColor: theme.colors.backgroundGray[0],
    padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`,
    [utils.smallerThan('lg')]: {
      width: '100%'
    },
    [utils.smallerThan('md')]: {
      padding: '16px'
    },
    [utils.largerThan('lg')]: {
      marginRight: 290
    },
    [utils.largerThan('md')]: {
      marginLeft: 290
    }
  },

  section: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  },

  scrollContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: '8px',
    height: '100%',
    backgroundColor: theme.colors.backgroundGray[0]
  },

  headerMenu: {
    backgroundColor: 'white',
    padding: '10px 0 0 10px',
    [utils.largerThan('md')]: {
      display: 'none'
    }
  },

  headerMenuBurger: {
    div: {
      height: '2px'
    }
  },
  simpleNavbar: {
    backgroundColor: theme.colors.backgroundGray[0],
    position: 'fixed',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    [utils.smallerThan('md')]: {
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      marginLeft: theme.spacing.xs,
      backgroundColor: 'white',
      padding: '0'
    }
  },
  select: {
    '& .mantine-Select-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  }
}));
