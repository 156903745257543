import React, { FC, useCallback, useContext, useState } from 'react';
import { SimpleGrid, TextInput, Button, Box, UnstyledButton } from '@mantine/core';
import { useEditRolesMutation } from '@/entities/admin-app/roles/api';
import { useForm, FormErrors } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { PanelsEnum } from '@/types/enums/roles';
import { IRolesInfoCommon } from '@/entities/admin-app/roles';
import { useActions } from '@hooks/redux/action';
import { IconPlus } from '@tabler/icons';
import { SelectGroupSimpleModal } from '@components/modal/select-group-simple';
import { SelectUserModal } from '@components/modal/select-user';
import { IUserTableData } from '@/entities/admin-app/users';
import { Badges } from '../../../badges';
import { themeColors } from '@/theme/colors';
import { useStyles } from '../../../create-item/styles';
import { transformObjectIfKeyIsNull } from '@/containers/pages/roles/utils';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateCreateRolesSchema } from '@/containers/pages/roles/components/schemes/validate';
import { useTranslation } from 'react-i18next';
import { RolesInfoMainContext } from '../../panels';
import { canEditRole } from '@/lib/utils/access';
import { SelectQuotas } from '@/containers/pages/user/components/Quotaselect';
import { universalNotification } from '@/lib/utils/notification';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/containers/routes/constants';

interface Props {
  editedItem?: IRolesInfoCommon;
}
export interface IBadges {
  Name: string;
}

export const EditRolesItem: FC<Props> = ({ editedItem }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);
  const { setSelectedRolesId, setSelectedPanel } = useActions();
  const navigate = useNavigate();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState<Pick<IUserTableData, 'Name' | 'Id'> | null>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (editedItem?.Owner?.Name && { Name: editedItem?.Owner?.Name }) || null
  );
  const [selectedRoles, setSelectedRoles] = useState<IBadges | null>(
    (editedItem?.Parent?.Name && { Name: editedItem?.Parent?.Name as any }) || null
  );

  const closeModals = () => {
    setIsUserModalOpen(false);
    setIsRoleModalOpen(false);
  };

  const goBack = useCallback(() => {
    setSelectedPanel(PanelsEnum.generalInformation);
  }, []);

  const isMain = editedItem?.ParentName == null && editedItem?.Id == 1;
  const form = useForm({
    initialValues: {
      Id: editedItem?.Id || null,
      Customer: {
        Id: editedItem?.Customer?.Id || null,
        Name: editedItem?.Customer?.Name || null
      },
      Type: 1,
      Name: editedItem?.Name,
      Parent: {
        Id: editedItem?.Parent?.Id || null
      },
      Owner: {
        Id: editedItem?.Owner?.Id || null,
        Name: editedItem?.Owner?.Name || null
      },
      Quota: {
        Id: editedItem?.Quota?.Id || null,
        Name: editedItem?.Quota?.Name || ''
      }
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(validateCreateRolesSchema(t), values)
  });

  const isDisabled = !form.isValid() || !form.isDirty();

  const [editRoles] = useEditRolesMutation();
  const handleSubmit = async (values: any) => {
    const formatted = transformObjectIfKeyIsNull(JSON.parse(JSON.stringify(values)));
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { Parent, ...IsMineData } = values;
      const res = await editRoles(!isMain ? formatted : IsMineData);

      if (!Object.keys(res).includes('error')) {
        handleClose();
        universalNotification({
          error: null,
          isError: false,
          isSuccess: true,
          successTitle: t('roles.successEdit')
        });
      } else {
        const codeError = (res as any)?.error?.data?.Code;
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err: any) {
      console.warn('Edit role ERROR', err);
    }
  };
  const setGroupId = async (id: number) => {
    form.setFieldValue('Parent.Id', id);
    closeModals();
  };

  const setGroup = async (group: any) => {
    setSelectedRoles({ Name: group?.label });
  };
  const handleClose = useCallback(() => {
    setSelectedPanel(PanelsEnum.generalInformation);
    setSelectedRolesId(0);
    form.reset();
    navigate(ROUTES.roles.path);
  }, []);

  const handleBack = () => {
    goBack();
    form.reset();
  };

  const handleRemoveRoles = () => {
    setSelectedRoles(null);
    form.setFieldValue('Parent.Id', null);
  };

  const handleRemoveUser = () => {
    setSelectedUser(null);
    form.setFieldValue('Owner.Id', null);
    form.setFieldValue('Owner.Name', null);
  };

  const handleConfirmSelectUserModal = async () => {
    if (!selectedUser) {
      return;
    }
    form.setFieldValue('Owner.Id', selectedUser?.Id);
    form.setFieldValue('Owner.Name', selectedUser?.Name);
    closeModals();
  };

  const handleCloseSelectUserModal = () => {
    setIsUserModalOpen(false);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      {canEditRole(rolesInfoCommon?.Type) && rolesInfoCommon ? (
        <SimpleGrid cols={1} w="100%" mt={24}>
          <TextInput
            placeholder="Admin"
            label={t('roles.name')}
            withAsterisk
            data-value="Name"
            {...form.getInputProps('Name')}
          />

          {!isMain && (
            <SelectWithPagination
              form={form}
              fieldName="Customer.Id"
              label={t('roles.addFilial')}
              placeholder={t('choose')}
              list={[]}
              defaultValue={form.getInputProps('Customer.Id').value || null}
              value={form.getInputProps('Customer.Id').value}
              isRoleComponent
              filialName={form.getInputProps('Customer.Name').value}
              customerField="Customer.Name"
            />
          )}

          <SimpleGrid cols={1} spacing="xs">
            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => setIsRoleModalOpen(true)}
            >
              {t('roles.addParentRole')}
            </Button>
            {selectedRoles && (
              <Box>
                <Badges label={selectedRoles?.Name} onRemove={handleRemoveRoles} />
              </Box>
            )}
          </SimpleGrid>
          <SimpleGrid cols={1} spacing="xs">
            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightSection={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => setIsUserModalOpen(true)}
            >
              {t('roles.addRoleManager')}
            </Button>
            {form.values.Owner.Name && (
              <Box>
                <Badges label={form.values.Owner.Name} onRemove={handleRemoveUser} />
              </Box>
            )}
          </SimpleGrid>
        </SimpleGrid>
      ) : null}
      <SimpleGrid cols={1} w="100%" mt={24}>
        <SelectQuotas
          // defaultValue={userQuotaForm.values.Id}
          form={form}
          fieldName="Quota.Id"
          quotaField="Quota.Name"
          value={form.values.Quota.Id}
          QuotaName={form.values.Quota.Name}
          Customer={form.values.Customer.Id}
        />
      </SimpleGrid>

      <SimpleGrid cols={{ base: 1, sm: 2 }} mt="md">
        <Button type="submit" disabled={isDisabled}>
          {t('save')}
        </Button>
        <UnstyledButton onClick={handleBack} className={classes.cancelBtn}>
          {t('cancel')}
        </UnstyledButton>
      </SimpleGrid>
      <SelectGroupSimpleModal
        onClose={closeModals}
        isOpened={isRoleModalOpen}
        title={t('roles.addParentRole')}
        setGroupId={setGroupId}
        setGroup={setGroup}
        SkipIds={editedItem?.Id ? [editedItem?.Id] : []}
      />
      <SelectUserModal
        multipleUsers={false}
        isOpened={isUserModalOpen}
        title={t('roles.addRoleManager')}
        onClose={closeModals}
        setSelectedUsers={setSelectedUser}
        userId={selectedUser?.Id}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: t('add'),
            fn: handleConfirmSelectUserModal
          },
          cancelProps: {
            btnName: t('cancel'),
            fn: handleCloseSelectUserModal
          }
        }}
      />
    </form>
  );
};
