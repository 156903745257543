import React, { FC, PropsWithChildren, useRef, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box, Flex, Text, LoadingOverlay, Burger, Loader } from '@mantine/core';

import { useActions } from '@hooks/redux/action';

import { ErrorBoundary } from '@components/error-boundary';
import { SimpleNavbar } from '@components/simple-navbar';
import { Layout } from '../../../layout';
import { NavMenu } from './components/nav-menu';
import { NotFound } from '@components/not-found';

import { useGetFilialsInfoCommonQuery } from '@/entities/admin-app/filials/api';
import { CompanyPlaceholder } from '@assets/icons';
import { useStyles } from '../styles';

const FilialEdit: FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef(null);

  const { setFilial } = useActions();
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { id = '' } = useParams();

  const {
    isLoading,
    data: filialData,
    error
  } = useGetFilialsInfoCommonQuery({
    id: +id,
    extended: true
  });

  useEffect(() => {
    if (isLoading || !filialData) {
      return;
    }
    setFilial(filialData);
  }, [isLoading, filialData]);

  if ((id !== undefined && isNaN(Number(id))) || error) {
    return <NotFound />;
  }

  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" w="100vw" h="100vh">
          <Loader />
        </Flex>
      ) : (
        <Layout
          leftNavbarContent={
            <SimpleNavbar
              widthNav={290}
              className={classes.simpleNavbar}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            >
              <NavMenu setIsOpen={setIsOpen} />
            </SimpleNavbar>
          }
        >
          <ErrorBoundary componentTitle="Filial edit page">
            <Box
              className={classes.wrapper}
              ref={containerRef}
              style={{ flexGrow: 1, overflow: 'auto' }}
              mt="md"
            >
              {isLoading ? (
                <LoadingOverlay visible={isLoading} overlayProps={{ blur: 2 }} />
              ) : (
                <>
                  <Flex mb="md" align="center" className={classes.headerMenu}>
                    <Burger
                      opened={isOpen}
                      onClick={() => setIsOpen(true)}
                      className={classes.burgerMenu}
                      size="sm"
                    />
                    <CompanyPlaceholder size={24} stroke="#101828" />
                    <Text className={classes.titleContainer} px="xs" color="#101828" fw={600}>
                      {filialData?.Title}
                    </Text>
                  </Flex>
                  <Outlet />
                </>
              )}
            </Box>
            <Box className={classes.section}>{children}</Box>
          </ErrorBoundary>
        </Layout>
      )}
    </>
  );
};

export default FilialEdit;
