import { z } from 'zod';
import { limitsLengthFilialFields } from '@/containers/pages/filials/utils';
import { TFunction } from 'i18next';

export const quotaLinkRegex =
  /https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/(roles|docs|common|shared-to-me|shared-access|favorites|rooms|profile)(?:\/\d+)?/;

// const validateScheme = z.object({
//   object: z.string().min(1, { message: t('filials.diskSpace.pastUrl') }),
//   quotaId: z.number({
//     invalid_type_error: t('validation.quotaMandatory')
//   }),
//   name: z
//     .string()
//     .trim()
//     .min(1, { message: t('quota.errors.required') })
//     .max(64, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) }),
//   size: z
//     .number({
//       required_error: t('filials.diskSpace.errors.size'),
//       invalid_type_error: t('filials.diskSpace.errors.type')
//     })
//     .max(1000, { message: t('filials.diskSpace.errors.max') })
//     .positive({ message: t('filials.diskSpace.errors.positive') })
//     .safe()
// });

const createQuotaRelationScheme = (t: TFunction) =>
  z.object({
    NewQuota: z.literal(true),
    Url: z
      .string()
      .min(1, { message: t('filials.diskSpace.pastUrl') })
      .regex(quotaLinkRegex, { message: t('quota.errors.wrongLink') }),
    Quota: z.object({
      Name: z
        .string()
        .trim()
        .min(1, { message: t('quota.errors.required') })
        .max(64, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) }),
      Size: z
        .number({
          required_error: t('filials.diskSpace.errors.size'),
          invalid_type_error: t('filials.diskSpace.errors.type')
        })
        .max(1000, { message: t('filials.diskSpace.errors.max') })
        .positive({ message: t('filials.diskSpace.errors.positive') })
        .safe()
    })
  });

const useQuotaRelationScheme = (t: TFunction) =>
  z.object({
    NewQuota: z.literal(false),
    Url: z
      .string()
      .min(1, { message: t('filials.diskSpace.pastUrl') })
      .regex(quotaLinkRegex, { message: t('quota.errors.wrongLink') }),
    QuotaId: z.number({
      invalid_type_error: t('validation.quotaMandatory')
    })
  });

export const validateQuotaRelationScheme = (t: TFunction) =>
  z.union([createQuotaRelationScheme(t), useQuotaRelationScheme(t)]);

export const settingsPageValidateScheme = (t: TFunction) =>
  z.object({
    Title: z
      .string()
      .max(limitsLengthFilialFields.Title, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Title
        })
      })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('validation.enterFilialName')
      }),
    Company: z
      .string()
      .max(limitsLengthFilialFields.Company, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Company
        })
      })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      })
      .refine((value) => value.length > 0, {
        message: t('quota.errors.required')
      })
      .or(z.literal('')),
    Parent: z
      .object({
        Id: z.number({ invalid_type_error: t('filials.settings.chooseParent') })
      })
      .nullable(),
    IsParentSettings: z.boolean()
  });

export const createFilialSchema = (t: TFunction) =>
  z.object({
    Company: z
      .string()
      .max(limitsLengthFilialFields.Company, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Company
        })
      })
      .nullable(),
    Title: z
      .string()
      .min(1, { message: t('filials.isRequiredField') })
      .max(limitsLengthFilialFields.Title, {
        message: t('validation.maxLengthSymbolsWithCount.symbols', {
          count: limitsLengthFilialFields.Title
        })
      })
      .refine((value) => value.trim().length > 0, {
        message: t('validation.emptyInput')
      }),
    Parent: z.object({
      Id: z.number({ invalid_type_error: t('filials.isRequiredField') })
    })
  });
