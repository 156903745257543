import {
  ActionIcon,
  Box,
  Button,
  Card,
  Group,
  Image,
  LoadingOverlay,
  Pagination,
  SimpleGrid,
  Stack,
  Text,
  Tooltip
} from '@mantine/core';

import iconDesktopActivatedSrc from '@assets/icon-desktop-activaded.svg';
import iconDesktopDeactivatedSrc from '@assets/icon-desktop-deactivated.svg';
import { IconLink } from '@tabler/icons-react';
import { IconCircleCheck, IconUpload } from '@tabler/icons';
import { LicenseItem, LicenseStatus } from '@/entities/admin-app/licenses';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { notifications } from '@mantine/notifications';
import r7EditorIconSrc from '@assets/icon-r7-editor.svg';
import organizerIconSrc from '@assets/icon-organizer.svg';
import organizerProIconSrc from '@assets/icon-organizer-pro.svg';
import drawIconSrc from '@assets/icon-draw.svg';
import { useTranslation } from 'react-i18next';
import i18n from '@/entities/i18next';

export interface LicensesListProps {
  isActive: boolean;
  isLoading: boolean;
  data: LicenseItem[];
  total: number;
  page: number;
  onPageChange: (page: number) => void;
  onActionClick: (item: LicenseItem) => void;
}

const productTypesMap: Record<string, { src: string; label: string }> = {
  DTB: {
    src: r7EditorIconSrc,
    label: i18n.t('license.editorDesktop')
  },

  DAU: {
    src: r7EditorIconSrc,
    label: i18n.t('license.auroraDesktop')
  },
  DTP: {
    src: organizerIconSrc,
    label: i18n.t('license.organiser')
  },
  DTR: {
    src: organizerProIconSrc,
    label: i18n.t('license.organiserPro')
  },
  DGR: {
    src: drawIconSrc,
    label: i18n.t('license.desktopDraw')
  }
};

export const LicensesList = ({
  isActive,
  isLoading,
  data,
  page,
  total,
  onActionClick,
  onPageChange
}: LicensesListProps) => {
  const { copy } = useCopyToClipboard();
  const { t } = useTranslation();

  const handleCopyText = async (text: string) => {
    await copy(text);
    notifications.show({ message: t('license.copied') });
  };

  return (
    <Stack bg="white" p="sm" pos="relative">
      <LoadingOverlay visible={isLoading} />
      <Text fz={18} fw={500}>
        {isActive ? t('license.activated') : t('license.deactivated')}
      </Text>
      <SimpleGrid cols={3}>
        {!data.length && <Text>Лицензий нет</Text>}
        {data.map((item) => (
          <Card key={item.Id} withBorder radius="md" padding="sm">
            <Stack gap="xs">
              <Group gap="sm">
                <Image src={isActive ? iconDesktopActivatedSrc : iconDesktopDeactivatedSrc} />
                <Stack gap={2}>
                  <Text size="sm" fw={500}>
                    {item.OperatingSystem}
                  </Text>
                  <Group gap="xs">
                    <Image w={20} src={productTypesMap[item.ProductType].src} />
                    <Text size="sm">{productTypesMap[item.ProductType].label}</Text>
                  </Group>
                </Stack>
              </Group>
              <Stack gap={2}>
                <Group gap="xs">
                  <Text size="sm" c="dimmed">
                    {t('license.device')}:
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.MachineId.slice(0, 10)}
                  </Text>
                  {!!item.InstallationGuid && (
                    <>
                      <Text size="sm" c="dimmed">
                        {item.InstallationGuid}
                      </Text>
                      <Tooltip withArrow label={t('copy')}>
                        <ActionIcon
                          color="dark"
                          size="sm"
                          mt={-4}
                          onClick={() => handleCopyText(item.InstallationGuid)}
                        >
                          <IconLink size={16} />
                        </ActionIcon>
                      </Tooltip>
                    </>
                  )}
                </Group>
                <Group gap="xs">
                  <Text size="sm" c="dimmed">
                    IP-adress:
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.MachineIp}
                  </Text>
                </Group>
                <Group gap="xs">
                  <Text size="sm" c="dimmed">
                    Host name:
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.MachineHostname}
                  </Text>
                  <Text size="sm" c="dimmed">
                    {isActive ? t('license.activationDate') : t('license.deactivationDate')}:
                  </Text>
                  <Text size="sm" c="dimmed">
                    {new Date(item.StartDate).toLocaleDateString()}
                  </Text>
                </Group>
                <Group gap="xs">
                  <Text size="sm" c="dimmed">
                    {t('users.status')}:
                  </Text>
                  <Text size="sm" c="dimmed">
                    {item.Status === LicenseStatus.Active
                      ? t('license.activeLicense')
                      : t('license.deactiveLicense')}
                  </Text>
                </Group>
                <Group>
                  {!isActive && (
                    <Button
                      variant="transparent"
                      px={0}
                      fw={400}
                      onClick={() => onActionClick(item)}
                      leftSection={<IconCircleCheck />}
                    >
                      {t('license.activate')}
                    </Button>
                  )}
                  {isActive && (
                    <Button
                      variant="transparent"
                      color="red"
                      px={0}
                      fw={400}
                      onClick={() => onActionClick(item)}
                      leftSection={
                        <Box component={IconUpload} sx={{ transform: 'rotate(90deg)' }} />
                      }
                    >
                      {t('license.deactivate')}
                    </Button>
                  )}
                </Group>
              </Stack>
            </Stack>
          </Card>
        ))}
      </SimpleGrid>
      <Pagination
        size="sm"
        value={page}
        total={total}
        onChange={onPageChange}
        styles={{ root: { alignSelf: 'center' } }}
      />
    </Stack>
  );
};
