import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  radio: {
    padding: '5px 0',

    '& .mantine-Radio-inner': {
      alignSelf: 'center'
    },

    '& .mantine-Radio-label': {
      fontSize: '14px',
      paddingLeft: theme.spacing.xs
    }
  },

  textFieldInput: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px',
      fontWeight: 400
    },

    '& .mantine-TextInput-input': {
      borderRadius: '4px',
      border: '1px solid #D0D5DD'
    }
  }
}));
