import { FC } from 'react';
import { useForm, FormErrors } from '@mantine/form';
import { Box, Button, SimpleGrid, UnstyledButton, TextInput, LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useStyles } from '../../styles';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { useCreateFilialMutation } from '@/entities/admin-app/filials/api';
import { useTranslation } from 'react-i18next';
import { getValidateErrors } from '@/lib/utils/validation';
import { createFilialSchema } from '@/containers/pages/filials/schemes/validate';
import { clearApiCacheByTags } from '@/entities/helpers';
import { rolesApi } from '@/entities/admin-app/roles/api';

interface ICreateFilialFormProps {
  onClose: () => void;
}

interface ICreateFilial {
  Company?: string;
  Title: string;
  Parent: {
    Id: number | string;
    Name: string;
  };
}

export const CreateFilialForm: FC<ICreateFilialFormProps> = ({ onClose }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [createFilial, { isLoading }] = useCreateFilialMutation();
  const form = useForm<ICreateFilial>({
    initialValues: {
      Company: '',
      Title: '',
      Parent: {
        Id: '',
        Name: ''
      }
    },
    validateInputOnChange: true,
    validate: (values: ICreateFilial): FormErrors =>
      getValidateErrors(createFilialSchema(t), values)
  });

  const handleSubmit = async (values: any) => {
    const filialValues = {
      ...values,
      Title: values.Title.trim()
    };
    try {
      const res = await createFilial(filialValues);

      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data?.ErrorMessage}`,
          message: '',
          autoClose: true,
          color: 'red',
          style: { zIndex: 1003 }
        });
      } else {
        showNotification({
          title: t('filials.filialSuccess'),
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        onClose();
        clearApiCacheByTags(rolesApi, ['Roles']);
      }
    } catch (err: any) {
      console.warn(t('filials.filialError'), err);
    }
  };

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <>
      <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
        <Box className={classes.modalContainer}>
          <SimpleGrid cols={1} w="100%">
            <TextInput
              placeholder={t('filials.settings.filialName')}
              label={t('filials.addName')}
              withAsterisk
              data-value="Title"
              {...form.getInputProps('Title')}
            />
            <TextInput
              placeholder={t('filials.settings.filialCode')}
              label={t('filials.settings.filialCode')}
              data-value="Company"
              {...form.getInputProps('Company')}
            />

            <SelectWithPagination
              form={form}
              fieldName="Parent.Id"
              defaultValue={null}
              label={t('filials.addParrent')}
              placeholder={t('profile.choose')}
              value={+form?.getTransformedValues().Parent?.Id}
              list={[]}
              filialName={form.getInputProps('Parent.Name').value}
              customerField="Parent.Name"
            />
          </SimpleGrid>
        </Box>
        <Box className={classes.modalFooter}>
          <Button size="sm" radius={8} w="49%" type="submit" disabled={!form.isValid()}>
            {t('create')}
          </Button>
          <UnstyledButton ml={8} className={classes.modalCancelBtn} w="49%" onClick={handleClose}>
            {t('cancel')}
          </UnstyledButton>
        </Box>
      </form>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};
